/* Sticky footer: begin */
html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
  width: 100%;
}

.page-wrapper {
  min-height: 100%;
  /* Equal to footer height */
  margin-bottom: -80px;
  background-color: #f5f5f5 !important;
}

.page-wrapper:after {
  content: "";
  display: block;
  height: 100px;
}

.page-footer {
  height: 80px;
  background-color: #2c2f33;
  display: table;
  width: 100%;
  text-align: center;
}
/* Sticky footer: end */

/* Sticky footer login: begin */
.login-page-wrapper {
  min-height: 100%;
  margin-bottom: -20px;
}

.login-page-wrapper:after {
  content: "";
  display: block;
  height: 20px;
}

.login-page-footer {
  height: 20px;
  display: table;
  width: 100%;
  text-align: center;
}

.login-page-wrapper,
.login-page-footer {
  background-color: #f5f5f5;
}
/* Sticky footer login: end */

.page-header {
  height: 72px;
}

.page-header .navbar {
  padding: 14px 32px !important;
}

.logo {
  width: 132px;
  height: 44px;
  object-fit: contain;
}

.profile-img {
  width: 40px;
  height: 40px;
  object-fit: contain;
  border-radius: 8px;
  background-color: #f5f5f5;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  text-decoration: none;
}

.user-notification-wrapper {
  width: 24px;
  height: 24px;
  margin: 0 24px;
  position: relative;
}

.user-notification-img {
  width: 24px;
  height: 24px;
  margin-top: -10px;
}

.user-notification-badge {
  position: absolute;
  top: 0;
  right: 0;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  border: solid 2px #ffffff;
  background-color: #e64040;
  cursor: pointer;
}

.btn-base {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  border-radius: 8px;
  font-family: Inter-Regular_;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #1c1d1f;
  height: 32px;
}

.btn-text {
  vertical-align: middle;
}

.btn-tour {
  padding: 4px 21px 4px 8px;
  margin-top: -1px;
  margin-right: 24px;
  outline: 0 !important;
  box-shadow: none !important;
}

.btn:focus,
input:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.page-content-wrap {
  max-width: 1260px;
  margin: 0 auto;
}

.footer-centered {
  display: table-cell;
  vertical-align: middle;
}

.powered-by {
  width: 71px;
  height: 16px;
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #ffffff;
}

.project-index-panel {
  margin-top: 72px;
  padding: 10px 46px 10px 80px;
  border-radius: 8px;
  border: none !important;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
}

.project-index-panel .card-title {
  font-family: Inter-Regular_;
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #1c1d1f;
  margin-bottom: 16px !important;
}

.project-index-panel .card-subtitle {
  font-family: Inter-Regular_;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #8a8b8b;
  margin-bottom: 48px !important;
}

.project-index-panel .card-body {
  padding: 0 !important;
  position: relative;
}

.project-card .card-body.ghost .ghost-btn {
  display: block !important;
}

.project-card .card-body .collaborator-icon {
  width: 24px;
  height: 24px;
  background: url("../img/icon-users@2x.png") no-repeat;
  background-size: cover;
  position: absolute;
  right: 16px;
  bottom: 16px;
}

.project-index-panel .btn {
  margin-right: 16px;
}

.btn-basic {
  height: 40px !important;
  font-family: Inter-Regular_;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  padding: 10px 24px;
  color: #ffffff !important;
  background-color: #5060ff;
}

.btn-basic:hover {
  color: #ffffff;
  background-color: #3f4ccc;
}

.project-index-panel .btn:first-child {
  color: #ffffff;
}

.project-index-panel .btn:last-child {
  color: #1c1d1f;
}

.btn-outline-light-gray {
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
}

.table {
  display: table;
  padding: 0;
  margin: 0;
}

.table-cell {
  display: table-cell;
}

.vertical-align-middle {
  vertical-align: middle;
}

.inline-block {
  display: inline-block;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.border-error {
  box-shadow: 0 0 4px 0 rgba(230, 64, 64, 0.48) !important;
  border: solid 1px #e64040 !important;
}

.border-success {
  box-shadow: 0 0 4px 0 rgba(92, 230, 64, 0.48) !important;
  border: solid 1px #4ee640 !important;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.no-outline {
  outline: 0 !important;
  box-shadow: none !important;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-direction-row {
  flex-direction: row;
}

.flex-start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.hidden {
  display: none !important;
}

.border-8-shadow {
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
}

.project-list-header {
  margin-top: 48px;
  padding: 0 !important;
}

.project-list-label {
  font-family: Inter-Regular_;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #1c1d1f;
  margin-right: 24px !important;
}

.search-bar {
  position: relative;
  width: 281px;
}

.search-icon-wrapper,
.search-text-input {
  border: none;
  border-radius: 0;
  background-color: transparent;
}

.search-icon-wrapper {
  padding: 0;
}

.search-text-input,
.search-text-input::placeholder {
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.search-text-input {
  height: 24px;
  padding: 0 0 0 12px !important;
  color: #1c1d1f;
}

.search-text-input::placeholder {
  color: #8a8b8b;
}

.search-text-input:focus {
  background-color: transparent !important;
  border: none !important;
  outline: 0;
  box-shadow: none;
}

.search-bar-bottom-border {
  height: 2px;
  background-color: #5060ff;
  width: 100%;
  position: absolute;
  bottom: -8px;
}

.nav-filter {
  margin-right: 72px;
}

.nav-filter .btn-group {
  line-height: 0;
  cursor: pointer;
}

.nav-filter-title {
  display: inline-block;
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #8a8b8b;
  margin-right: 4px;
}

.nav-filter-value {
  display: inline-block;
  font-family: Inter-Regular_;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #1c1d1f;
}

.nav-filter-dropdown-icon-wrapper {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 0;
}

/* Submenu items: begin */
.sub-menu .dropdown-menu {
  padding: 0 !important;
  border: none;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  min-width: auto;
}

.sub-menu .dropdown-menu a {
  font-family: Inter-Regular_;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #8a8b8b;
  padding: 10px 32px 10px 16px;
}

.sub-menu .dropdown-menu a {
  font-family: Inter-Regular_;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #8a8b8b;
}

.sub-menu .dropdown-menu a.active {
  color: #5160ff !important;
  background-color: transparent;
}

.sub-menu .dropdown-menu a.active:hover,
.sub-menu .dropdown-menu a:hover {
  background-color: rgba(80, 98, 255, 0.08) !important;
}
/* Submenu items: end */

/* Custom images for checkboxes:begin */
.custom-checkbox,
.custom-radio-button {
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  z-index: 1;
  top: 0;
  background-size: cover;
}

.custom-checkbox input[type="checkbox"],
.custom-radio-button input[type="radio"] {
  width: 20px;
  height: 20px;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  cursor: pointer;
  outline: none;
  opacity: 0;
  /* CSS hacks for adding opacity in older browsers */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -khtml-opacity: 0;
  -moz-opacity: 0;
}

.custom-checkbox {
  background: url("../img/unchecked@2x.png") no-repeat;
  background-size: cover;
}

.custom-checkbox.selected {
  background: url("../img/checked@2x.png") no-repeat;
  background-size: cover;
}

.custom-radio-button {
  background: url("../img/radio-btn@2x.png") no-repeat;
  background-size: cover;
  vertical-align: top;
}

.custom-radio-button.selected {
  background: url("../img/radio-btn-active@2x.png") no-repeat;
  background-size: cover;
  vertical-align: top;
}

@media (min-width: 576px) {
  .form-inline label {
    justify-content: normal;
  }
}

.nav-filter .custom-checkbox {
  margin-right: 8px;
}

.project-list-content {
  margin-top: 46px;
  display: flex;
  flex-wrap: wrap;
}
/* Custom images for checkboxes:begin */

/* Project card: begin */
.project-card {
  width: 296px;
  height: 308px;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  border: none;
  overflow: hidden;
  position: relative;
  margin: 9px;
  cursor: pointer;
}

.project-card .card-body {
  padding: 16px 24px;
}

.project-title {
  font-family: Inter-Regular_;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #1c1d1f;
  margin-bottom: -4px;
  line-height: 1.4;

  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  max-height: 50.4px;
}

.project-title a {
  color: #1c1d1f;
  text-decoration: none;
}

.project-customer {
  font-family: Inter-Regular_;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #5160ff;
  cursor: pointer;
}

.card-tags {
  line-height: 1.2;
  margin-top: 6px;
  margin-bottom: 14px;
}

.card-tag {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #8a8b8b;
  margin-right: 10px;
}

.project-date {
  font-family: Inter-Regular_;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #1c1d1f;
}

.profile-status {
  position: absolute;
  top: 16px;
  left: 16px;
  border-radius: 12px;
  background-color: #ffffff;
  height: 24px;
  padding: 0 6px;
  width: auto !important;
  line-height: 24px;
  cursor: unset !important;
}

.profile-status.ghost {
  width: 96px !important;
  height: 24px !important;
}

.project-card .status-badge {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 6px;
}

.not-started {
  background-color: #d6d6d6;
}

.in-progress {
  background-color: #f5ce2c;
}

.on-hold {
  background-color: #e64040;
}

.complete {
  background-color: #39cc3c;
}

.project-card .status-title {
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  color: #1c1d1f;
  vertical-align: middle;
  display: inline-block;
  margin-top: -3px;
  margin-right: 2px;
}

.project-card-submenu-icon {
  width: 32px;
  height: 32px;
  border-radius: 6px;
  background-color: #ffffff;
  line-height: 28px;
  text-align: center;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.submenu-dot {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #000000;
  vertical-align: middle;
  margin: 1px;
}

.project-card-submenu-wrapper {
  top: 12px;
  right: 12px;
  position: absolute;
  width: 32px;
  height: 32px;
  padding-bottom: -5px;
  display: none;
}

.project-card-submenu-wrapper .sub-menu {
  padding-bottom: 5px;
}

.project-card:hover > .project-card-submenu-wrapper {
  display: block;
}

.project-card:hover > .project-card-submenu-wrapper:hover {
  cursor: pointer;
}

.project-card-submenu-wrapper:hover .dropdown-menu {
  display: block;
}

.project-card-submenu-wrapper .dropdown-item:hover {
  color: #5160ff;
}
/* Project card: end */

/* Submenu images: begin */
.submenu-img.active {
  display: none;
}

.sub-menu .dropdown-item:hover > .submenu-img {
  display: none;
}

.sub-menu .dropdown-item:hover > .submenu-img.active {
  display: inline-block;
}

.sub-menu .dropdown-item:hover .user-profile-menu-item-text {
  color: #5160ff;
}

.sub-menu .submenu-img {
  margin-right: 8px;
}

/* Submenu images: end */

/* Create new Project modal: begin */
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 616px;
    margin: 120px auto;
  }
}

.create-project-modal {
  width: 616px;
}

.modal-open .page-wrapper,
.modal-open .page-footer {
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: blur(1px);
}

.modal-header .close {
  position: absolute;
  top: 16px;
  right: 26px;
  padding: 0 !important;
  margin: 0 !important;
  font-weight: 500;
  outline: none;
}

.modal-header .icon-72,
.basic-form.popup .icon-72 {
  margin-top: 16px;
  margin-bottom: 32px;
}

.modal-content {
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  border: none;
}

.modal-title {
  font-family: Inter-Regular_;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #1c1d1f;
}

.modal-sub-title {
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #1c1d1f;
  margin: 16px 0;
  padding: 0 50px;
}

.modal-header {
  padding: 32px 32px 16px 32px;
  border: none;
}

#create-project-modal .modal-body {
  padding: 0 !important;
}

#create-project-modal .nav-tabs .nav-item.show .nav-link,
#create-project-modal .nav-tabs .nav-link.active {
  background-color: #fff;
  border-color: transparent;
}

#create-project-modal .nav-tabs .nav-item {
  margin-left: 56px;
  height: 32px;
}

#create-project-modal .nav-tabs .nav-item:first-child {
  margin-left: 32px;
}

#create-project-modal .nav-tabs .nav-link {
  border: none;
  padding: 0;
}

#create-project-modal .nav-tabs .nav-link.active,
#create-project-modal .nav-tabs .nav-link:hover {
  background-color: #fff;
  border-bottom: 2px solid #5060ff;
}

.nav-link .tab-text,
.nav-link.active .tab-text {
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #e5e5e5;
}

#create-project-modal .nav-link.active .ord-number {
  color: #5160ff !important;
}

#create-project-modal .nav-tabs .nav-link.active .tab-text {
  color: #1c1d1f;
}

#create-project-modal .nav-tabs {
  border-bottom: 2px solid #dee2e6;
  height: 32px;
}

#create-project-modal .tab-content {
  padding: 24px 20px 32px 32px;
}

.form-field-wrapper .form-label {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #1c1d1f;
  margin-bottom: 4px;
}

.form-field-wrapper {
  margin-bottom: 16px;
}

.form-field-wrapper:last-child {
  margin-bottom: 0;
}

.form-field-wrapper input,
.form-field-wrapper input::placeholder,
.form-field-wrapper textarea,
.form-field-wrapper textarea::placeholder,
.form-field-wrapper .dropdown-btn {
  font-family: Inter;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  font-size: 16px;
}

#file-group-input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  text-align: right;

  font-family: OpenSans;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #8a8b8b;
}

.project-logo-description {
  color: #8f9090;
}

.file-details-tab > a {
  font-family: Inter-Regular_;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #8a8b8b;
}

.nav-tabs .nav-link:hover {
  border-top-color: #fff;
  border-left-color: #fff;
  border-right-color: #fff;
}

.nav-tabs .nav-item.nav-link.active {
  font-family: Inter-Regular_;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #5060ff;
  border-color: #fff #fff #5060ff;
}

.form-field-wrapper input,
.form-field-wrapper textarea,
.form-field-wrapper .dropdown-btn {
  border-radius: 8px;
  border: solid 1px #e0e0e0;
  background-color: rgba(255, 255, 255, 0.01);
  width: 100%;
  min-height: 40px;
  padding: 4px 16px;
  color: #1c1d1f;
  text-align: left;
}

.form-field-wrapper .dropdown-btn:hover {
  background-color: transparent;
  color: #1c1d1f;
}

.privacy,
.privacy .dropdown,
.privacy .dropdown-menu {
  width: 100%;
}

.privacy .dropdown-item:hover {
  color: #5160ff !important;
}

.form-field-wrapper input::placeholder,
.form-field-wrapper textarea::placeholder {
  color: #8a8b8b;
}

.form-field-wrapper input:focus,
.form-field-wrapper textarea:focus {
  border: 1px solid #5060ff;
}

.form-field-wrapper textarea {
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none; /*remove the resize handle on the bottom right*/
  min-height: 136px;
}

.form-field-wrapper .dropdown-wrapper {
  width: 280px;
  line-height: 40px;
}

.form-field-wrapper .dropdown-wrapper input {
  float: left;
  padding-right: 40px;
}

.form-field-wrapper .dropdown-wrapper .icon-dropdown {
  width: 24px;
  height: 24px;
  background: url(../img/icon-dropdown@2x.png) no-repeat right bottom;
  background-size: cover;
  display: inline-block;
  margin-left: -40px;
  margin-top: 8px;
  vertical-align: middle;
  cursor: pointer;
}

.form-field-wrapper .dropdown-wrapper .active .icon-dropdown {
  background: url(../img/icon-dropdown@2x.png) no-repeat left top;
}

.form-field-wrapper .privacy .icon-dropdown {
  width: 24px;
  height: 24px;
  background: url(../img/icon-dropdown@2x.png) no-repeat right bottom;
  background-size: cover;
  display: inline-block;
  margin-left: -40px;
  vertical-align: middle;
  cursor: pointer;
}

.form-field-wrapper .privacy .icon-dropdown.active {
  background: url(../img/icon-dropdown@2x.png) no-repeat left top;
  background-size: cover;
}

.form-field-wrapper .dropdown-wrapper .dropdown-menu a {
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1c1d1f;
  padding: 8px 24px;
}

.db-imp {
  display: block !important;
}

.form-field-wrapper .dropdown-wrapper .dropdown-menu a.add-link {
  background-color: #5060ff;
  color: #ffffff;
}

.form-field-wrapper .dropdown-wrapper .dropdown-menu a.add-link:hover {
  background-color: #5060ff;
  color: #ffffff;
}

.form-field-wrapper .dropdown-wrapper .dropdown-menu .dropdown-item:active,
.form-field-wrapper .dropdown-wrapper .dropdown-menu .dropdown-item:hover {
  background-color: rgba(80, 96, 255, 0.08);
  color: #1c1d1f;
}

.form-field-wrapper .dropdown-wrapper > div {
  width: 100%;
}

.form-field-wrapper .dropdown-wrapper .dropdown-menu {
  width: 100%;
  padding: 0;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  border: none;
  overflow: hidden;
}

.tag,
.tag-input {
  height: 32px;
  border-radius: 8px;
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 30px;
  padding: 0 8px 0 16px;
  display: inline-block;
  vertical-align: top;
  color: #1c1d1f;
}

.tag {
  border: 1px solid #e0e0e0;
}

.tag .tag-cross {
  font-size: 19px;
  margin-left: 12px;
  cursor: pointer;
}

.tag-input {
  height: 32px;
  min-height: 32px !important;
  padding: 4px 16px;
  width: auto !important;
  border: 1px dashed #e0e0e0 !important;
  font-size: 14px !important;
  background-color: transparent;
}

.tag-input:focus {
  border: 1px solid #5060ff !important;
}

.tag-input::placeholder {
  color: #8a8b8b !important;
  font-size: 14px !important;
}

.form-footer {
  border-top: none;
  padding: 0 32px 32px 32px;
}

#create-project-modal .form-footer {
  justify-content: space-between;
}

.modal-body button,
.file-detail-button-group button,
.form-footer button,
.header-btn-wrapper button.btn,
.page-header .form-inline .logout-btn {
  font-family: Inter-Regular_;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 8px;
  background-color: #5060ff;
  padding: 9px 24px;
}

/* .page-header .form-inline button {
  width: ;
} */

.form-footer button:hover {
  color: #ffffff;
  background-color: #3f4ccc;
}

.modal-body button:hover,
.file-detail-button-group button:hover {
  color: #ffffff;
  background-color: #3f4ccc;
}

.form-footer button.disabled {
  background-color: #d6d6d6;
}

.form-footer button.btn-delete {
  color: #ffffff;
  background-color: #e64040;
  border: none;
}

button.btn-outline-grey {
  color: #1c1d1f;
  background-color: transparent;
  border: 1px solid #e0e0e0;
}

button.btn-outline-grey:hover {
  color: #5160ff;
  background-color: transparent;
  border: 1px solid #5060ff;
}

.theme-settings-title {
  font-family: Inter-Regular_;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #1c1d1f;
  margin-bottom: 14px;
}

.theme-content-wrap {
  padding-bottom: 32px;
}

.theme-content-wrap:last-child {
  padding-bottom: 0;
}

.color-list .color-box-wrapper {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 36px;
  cursor: pointer;
}

.color-list .color-box {
  top: 0;
  left: 0;
  position: absolute;
  width: 36px;
  height: 36px;
  text-align: center;
  border-radius: 10px;
  opacity: 0.5;
  padding: 1px;
  display: none;
}

.color-list .color-box-inner {
  top: 2px;
  left: 2px;
  position: absolute;
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: 2px solid #ffffff;
}

.color-list .color-box-wrapper:hover > .color-box {
  display: block;
}

.color-theme-box-main {
  width: 160px;
  height: 112px;
  border-radius: 8px;
}

.color-theme-box-dark {
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}

.color-theme-box-light {
  background-color: #f7f7f7;
}

.color-theme-box-cell {
  width: 160px;
  float: left;
  margin-right: 24px;
}

.color-theme-box-cell label .color-theme-box-text,
.template-item label {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: right;
  color: #1c1d1f;
  margin-left: 8px;
  vertical-align: top;
}

#create-project-modal label {
  margin-top: 16px;
}

.color-theme-box-cell label,
.template-item label {
  margin-top: 16px;
}

.scrollable-themes-wrapper {
  max-height: 500px;
  overflow: auto;
  padding-right: 12px;
}

.template-item-wrapper {
  margin-bottom: 24px;
}

.template-item-wrapper label {
  margin-bottom: 0;
}
/* Create new Project modal: end */

/* Project builder: begin */

.project-wrapper {
  margin-top: 48px;
}

.project-sidebar {
  width: 320px;
  padding-right: 24px;
  float: left;
  margin-bottom: 32px;
}

.project-content {
  padding-left: 320px;
  width: 100%;
}

@media (max-width: 970px) {
  .project-sidebar {
    float: none;
  }

  .project-content {
    padding-left: 0px;
  }
}

@media (max-width: 320px) {
  .project-sidebar {
    width: 230px;
  }
}

@media (max-width: 230px) {
  .project-sidebar {
    width: 150px;
  }

  .menu-btn-title {
    display: none;
  }
}

.project-content.view {
  padding-left: 0;
}

.project-sidebar .header,
.project-content .header {
  display: flex;
  justify-content: space-between;
  height: 40px;
}

.project-sidebar .header > .link {
  text-decoration: none;
}

.menu-group {
  margin-bottom: 32px;
}

.menu-group .menu-group-title {
  font-family: Inter-Regular_;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #8a8b8b;
  margin-bottom: 16px;
}

.left-arrow-img {
  line-height: 1.5;
}

.header-element-wrapper:hover {
  text-decoration: none;
}

.header-element-wrapper > * {
  vertical-align: top;
}

.flex-space {
  display: flex;
  justify-content: space-between;
}

.project-sidebar .header .title {
  font-family: Inter-Regular_;
  font-size: 21px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #1c1d1f;
}

.project-sidebar .header-element-wrapper {
  align-self: center;
  cursor: pointer;
}

.project-sidebar .header .title,
.project-sidebar .header .left-arrow-img {
  line-height: 24px;
  margin-right: 16px;
  vertical-align: middle;
}

.project-sidebar > .content,
.project-content > .content {
  margin-top: 32px;
}

@media (max-width: 970px) {
  .project-sidebar .header,
  .project-content .header {
    display: block;
  }

  .project-content > .content {
    margin-top: 64px;
  }

  .header-btn-wrapper {
    margin-bottom: 10px;
  }
}

.project-sidebar .menu-group .menu-btn {
  height: 44px;
  margin-bottom: 8px;
  border-radius: 8px;
  border: solid 1px #e0e0e0;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding-right: 12px;
  position: relative;
}

.project-sidebar .menu-group .menu-btn.disabled,
.project-sidebar .menu-group .menu-btn.disabled:hover {
  height: 44px;
  margin-bottom: 8px;
  border-radius: 8px;
  border: none !important;
  background-color: #f0f0f0 !important;
  cursor: default;
}

.project-sidebar .menu-group .menu-btn.disabled:hover .menu-btn-title {
  color: #8a8b8b;
}

.project-sidebar .menu-group .menu-btn.disabled:hover .menu-btn-move {
  opacity: 0;
}

.project-sidebar .menu-group .menu-btn:hover {
  border: solid 1px #5160ff;
  cursor: pointer;
}

.project-sidebar .menu-group .menu-btn:hover .menu-btn-title {
  color: #5060ff;
}

.project-sidebar .menu-group .menu-btn .menu-btn-title {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #8a8b8b;
  margin-left: 8px;
  vertical-align: middle;
  line-height: 44px;
}

.project-sidebar .date {
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #8a8b8b;
}

.menu-btn-move {
  width: 24px;
  height: 24px;
  background: url(../img/icon-move@2x.png) no-repeat right bottom;
  background-size: cover;
  vertical-align: middle;
  display: inline-block;
  opacity: 0;
}

.project-sidebar .menu-group .menu-btn:hover .menu-btn-move {
  background: url(../img/icon-move@2x.png) no-repeat right bottom;
  background-size: cover;
  opacity: 1;
}

.card-img-top {
  width: 296px;
  height: 120px;
}

.menu-btn-eye {
  width: 20px;
  height: 20px;
  background: url(../img/icon-eye@2x.png) no-repeat left top;
  background-size: cover;
  vertical-align: middle;
  margin-left: auto;
  display: none;
}

.menu-btn:hover .menu-btn-eye {
  background: url(../img/icon-eye@2x.png) no-repeat right bottom;
  background-size: cover;
  display: inline-block;
}

.menu-btn.disabled:hover .menu-btn-eye {
  display: none;
}

.menu-btn-icon {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-left: 24px;
}

.menu-btn .icon-about-project,
.menu-btn.disabled:hover .icon-about-project {
  background: url(../img/icon-about-project@2x.png) no-repeat left top;
  background-size: cover;
}

.menu-btn:hover .icon-about-project {
  background: url(../img/icon-about-project@2x.png) no-repeat right bottom;
  background-size: cover;
}

.menu-btn .icon-links-tools,
.menu-btn.disabled:hover .icon-links-tools {
  background: url(../img/icon-links-tools@2x.png) no-repeat left top;
  background-size: cover;
}

.menu-btn:hover .icon-links-tools {
  background: url(../img/icon-links-tools@2x.png) no-repeat right bottom;
  background-size: cover;
}

.menu-btn .icon-team,
.menu-btn.disabled:hover .icon-team {
  background: url(../img/icon-team@2x.png) no-repeat left top;
  background-size: cover;
}

.menu-btn:hover .icon-team {
  background: url(../img/icon-team@2x.png) no-repeat right bottom;
  background-size: cover;
}

.menu-btn .icon-notes,
.menu-btn.disabled:hover .icon-notes {
  background: url(../img/icon-notes@2x.png) no-repeat left top;
  background-size: cover;
}

.menu-btn:hover .icon-notes {
  background: url(../img/icon-notes@2x.png) no-repeat right bottom;
  background-size: cover;
}

.menu-btn .icon-files,
.menu-btn.disabled:hover .icon-files {
  background: url(../img/icon-files@2x.png) no-repeat left top;
  background-size: cover;
}

.menu-btn:hover .icon-files {
  background: url(../img/icon-files@2x.png) no-repeat right bottom;
  background-size: cover;
}


.menu-btn .icon-actions,
.menu-btn.disabled:hover .icon-actions {
  background: url(../img/icon-actions@2x.png) no-repeat left top;
  background-size: cover;
}

.menu-btn:hover .icon-actions {
  background: url(../img/icon-actions@2x.png) no-repeat right bottom;
  background-size: cover;
}



.menu-btn .icon-roadmap,
.menu-btn.disabled:hover .icon-roadmap {
  background: url(../img/icon-roadmap@2x.png) no-repeat left top;
  background-size: cover;
}

.menu-btn:hover .icon-roadmap {
  background: url(../img/icon-roadmap@2x.png) no-repeat right bottom;
  background-size: cover;
}

.menu-btn .icon-tasks,
.menu-btn.disabled:hover .icon-tasks {
  background: url(../img/icon-tasks@2x.png) no-repeat left top;
  background-size: cover;
}

.menu-btn:hover .icon-tasks {
  background: url(../img/icon-tasks@2x.png) no-repeat right bottom;
  background-size: cover;
}

.project-content .header-btn {
  height: 40px;
  line-height: 40px;
  border-radius: 8px;
  font-family: Inter-Regular_;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #1c1d1f;
  display: inline-block;
}

.project-content .header-btn.grey-border {
  border: solid 1px #e0e0e0;
}

.project-content .header-btn.status-btn {
  background-color: #ffffff;
  padding: 0 8px 0 16px;
}

.project-content .status-title {
  margin: 0 23px 0 8px;
}

.status-circle {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 4px;
}

.project-content .header-btn {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}

.ghost-btn {
  border-radius: 8px;
  background-image: linear-gradient(110deg, #ebebeb, #f2f2f2 75%, #ebebeb);
  display: block;
  display: inline-block;
  letter-spacing: normal;
}

.last .ghost-btn {
  margin-left: 8px;
}

.h8 {
  height: 8px;
}

.h16 {
  height: 16px;
}

.h18 {
  height: 18px;
}

.h24 {
  height: 24px;
}

.h28 {
  height: 28px;
}

.h32 {
  height: 32px;
}

.h40 {
  height: 40px;
}

.h44 {
  height: 44px;
}

.h72 {
  height: 72px;
}

.h120 {
  height: 120px;
}

.h100p {
  height: 100%;
}

.w10 {
  width: 10px;
}

.w24 {
  width: 24px;
}

.w40 {
  width: 40px;
}

.w61 {
  width: 61px;
}

.w76 {
  width: 76px;
}

.w96 {
  width: 96px !important;
}

.w100 {
  width: 100px;
}

.w106 {
  width: 106px;
}

.w111 {
  width: 111px !important;
}

.w146 {
  width: 146px;
}

.w150 {
  width: 150px;
}

.w160 {
  width: 160px !important;
}

.w182 {
  width: 182px;
}

.w184 {
  width: 184px;
}

.w197 {
  width: 197px;
}

.w250 {
  width: 250px;
}

.w274 {
  width: 274px;
}

.w296 {
  width: 296px;
}

.w650 {
  width: 650px;
}

.w10p {
  width: 10%;
}

.w20p {
  width: 20%;
}

.w30p {
  width: 30%;
}

.w80p {
  width: 80%;
}

.w90p {
  width: 90%;
}

.w100p {
  width: 100%;
}

.mt5 {
  margin-top: 5px;
}

.mt6 {
  margin-top: 6px;
}

.mt8 {
  margin-top: 8px;
}

.mt12 {
  margin-top: 12px;
}

.mt16 {
  margin-top: 16px;
}

.mt20 {
  margin-top: 20px;
}

.mt24 {
  margin-top: 24px;
}

.mt32 {
  margin-top: 32px;
}

.mt40 {
  margin-top: 44px;
}

.mt44 {
  margin-top: 44px;
}

.mt88 {
  margin-top: 88px;
}

.mr8 {
  margin-right: 8px;
}

.mr20 {
  margin-right: 20px;
}

.mr27 {
  margin-right: 27px;
}

.mr40 {
  margin-right: 40px;
}

.mr79 {
  margin-right: 79px;
}

.mr93 {
  margin-right: 93px;
}

.mb16 {
  margin-bottom: 16px;
}

.dp {
  display: block;
}

.ghost-btn.r0 {
  border-radius: 0 !important;
}

.project-content .dropdown-item {
  font-family: Inter-Regular_;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #1c1d1f !important;
}

.project-content .dropdown-menu {
  min-width: 100%;
}

.project-content .header-btn.preview-btn {
  background-color: #5060ff;
  padding: 0 20px 0 12px;
  border: none !important;
  color: #ffffff !important;
  text-decoration: none !important;
}

.project-content .header-btn.preview-btn:hover {
  background-color: #3f4ccc;
}

.project-content .header-btn .icon {
  margin-top: -2px;
}

.project-content .header-btn.share-btn {
  padding: 0 20px 0 12px;
}

.project-content .header-btn .title {
  margin-left: 4px;
}

.project-content .header-btn.preview-btn,
.project-content .header-btn.share-btn {
  margin-right: 4px;
}

.header-btn-wrapper .sub-menu-btn {
  width: 40px;
  text-align: center;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.header-btn-wrapper .sub-menu-btn .dot {
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #000000;
  vertical-align: middle;
  margin-top: -4px;
  display: inline-block;
  margin-left: 2px;
}

.project-content .project-sheet {
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  overflow: hidden;
}

.project-content.view .project-sheet {
  border-radius: 0;
  box-shadow: none;
  overflow: visible;
}

.project-content .project-sheet .header {
  height: auto;
  display: block;
}

.project-content .project-sheet .header img {
  width: 100%;
}

.project-content .project-sheet > .header .content,
.project-content .project-sheet > .content {
  padding: 32px;
}

.project-content .project-sheet > .header h2.title {
  font-family: Inter-Regular_;
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
}

.project-content .project-sheet > .content h3.title {
  font-family: Inter-Regular_;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
}

.project-content .project-sheet > .content h4.title {
  font-family: Inter-Regular_;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  width: 100%;
  margin: 0;
  padding: 0;
}

.project-content .project-sheet > .content .group-header-wrapper {
  margin-top: 32px;
}

.project-content .project-sheet > .content > .description {
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.project-content .project-sheet > .content .panel .add-btn {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #00beff;
  cursor: pointer;
}

.project-content .project-sheet > .content .panel {
  display: flex;
  justify-content: space-between;
}

.project-content .project-sheet > .header .description,
.project-content .project-sheet > .header .description textarea {
  margin-top: 16px;
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.project-content .project-sheet > .header .content .title textarea {
  border-radius: 4px;
}

.project-content .project-sheet > .header .content textarea.read-only:focus {
  -moz-appearance: none;
  outline: 0px none transparent;
}

.project-content .project-sheet > .header .description textarea {
  width: 100%;
  background-color: transparent;
  border: solid 1px transparent;
  border-radius: 4px;
}

.project-content .project-sheet > .header .content .title textarea,
.project-content .project-sheet > .header .content .description textarea,
.edit-action .description textarea,
.project-view .header .content .description textarea,
.project-view .header .content .title textarea {
  border: 2px solid transparent !important;
  width: 100%;
  resize: none;
  overflow-wrap: break-word;
  overflow: hidden;
}

.project-view .header .content .title textarea,
.project-view .header .content .description textarea {
  background-color: transparent;
  outline: none;
  color: #ffffff;
}

.project-view .header .content .title textarea {
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
}

.project-view .header .content .description textarea {
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
}

.view-page-avatar-wrapper {
  background-color: #5060ff;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.project-view .header .content {
  padding: 100px 92px 0 92px;
}

.project-view .header .content .title textarea:focus,
.project-view .header .content .description textarea:focus {
  border: 2px solid #0000FF !important;
  border-radius: 5px;
}

.noshadow {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.project-content .project-sheet > .content .group-header-wrapper {
  padding: 6px 16px;
  display: flex;
}

.group-header-wrapper:hover {
  border-radius: 8px;
  background-color: #f5f5f5;
}

.group-header-wrapper.read-only:hover {
  border: none;
  background-color: transparent;
}

.group-header-wrapper .controls-wrapper {
  display: none;
  align-items: center;
}

.group-header-wrapper:hover .controls-wrapper {
  display: flex;
}

.group-header-wrapper.read-only:hover .controls-wrapper {
  display: none;
}

.group-header-wrapper .controls-wrapper > span {
  width: 24px;
  height: 24px;
  display: inline-block;
  cursor: pointer;
}

.group-header-wrapper .controls-wrapper > span.arrow-down {
  background: url(../img/icon-big-arrow-down@2x.png) no-repeat left top;
  background-size: cover;
  margin-right: 8px;
}

.group-header-wrapper .controls-wrapper > span.arrow-down.active {
  background: url(../img/icon-big-arrow-down@2x.png) no-repeat right bottom;
  background-size: cover;
}

.group-header-wrapper .controls-wrapper > span.arrow-up {
  background: url(../img/icon-big-arrow-up@2x.png) no-repeat left top;
  background-size: cover;
  margin-right: 16px;
}

.group-header-wrapper .controls-wrapper > span.arrow-up.active {
  background: url(../img/icon-big-arrow-up@2x.png) no-repeat right bottom;
  background-size: cover;
}

.group-header-wrapper .controls-wrapper > span.delete {
  background: url(../img/icon-delete-bucket@2x.png) no-repeat left top;
  background-size: cover;
}

.group-header-wrapper .controls-wrapper > span.delete.active {
  background: url(../img/icon-delete-bucket@2x.png) no-repeat right bottom;
  background-size: cover;
}

.project-content .project-sheet > .header .description textarea:focus {
  border: solid 1px #00beff;
  box-shadow: 0 0 4px 0 #00beff;
  -webkit-box-shadow: 0 0 4px 0 #00beff;
  -moz-box-shadow: 0 0 4px 0 #00beff;
}

.project-content .project-sheet > .content .description {
  color: #8a8b8b;
}

.project-content .project-sheet > .header .tags-wrapper {
  margin-top: 24px;
}

.links {
  display: inline-block;
  width: 100%;
}

.links .group-wrapper .empty {
  margin: 10px 0 !important;
}

.links .block,
.files .block {
  padding: 12px 8px 12px 12px;
  width: 274px;
  height: 125px;
  border-radius: 8px;
  float: left;
  margin-right: 18px;
  cursor: pointer;
  margin-bottom: 20px;
  position: relative;
  font-family: Inter-Regular_;
}

.card-details {
  position: absolute;
  bottom: 10px;
  font-size: 18px;
  font-weight: 500;
}

.files .file-block {
  margin-bottom: 20px;
  height: 110px;
}

.fileDownload {
  width: 20px;
  height: 20px;
}

.fileComment {
  margin-left: 10px;
  width: 20px;
  height: 20px;
}

.card-download-text {
  color: #67a8ed;
  margin-left: 5px;
}

.card-badge {
  float: right;
  margin-top: 5px;
}

.links .block:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.content .more-icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  opacity: 0;
}

.modal-content .more-icon {
  width: 24px;
  height: 24px;
  display: inline-block;
}

.modal-content .btn-group.action-btn {
  opacity: 0;
}

.modal-content .comment-item-wrapper:hover .btn-group.action-btn {
  opacity: 1;
}

.content .block:hover .more-icon,
.project-content .action-item:hover .more-icon,
.comment-item-wrapper:hover .more-icon {
  opacity: 1;
  cursor: pointer;
}

.comment-item-wrapper {
  position: relative;
}

.links .block .sub-menu {
  width: 24px;
  height: 24px;
}

.links .block .text,
.files .block .text,
.request-subject .block .text {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
  max-height: 100%;
  width: 156px;
}

.links .block .text > .title,
.files .block .text > .title,
.request-subject .block .text > .title {
  font-family: Inter-Regular_;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;

  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  max-height: 20px;
}

.links .block .text > .description,
.request-subject .block .text > .description {
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;

  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  max-height: 32px;
}

.project-sheet .header .img-wrapper {
  width: 100%;
  position: relative;
}

.project-sheet .header .img-wrapper .btn-group,
.project-sheet .header .img-wrapper .btn-group .sub-menu-btn {
  width: 40px;
  height: 40px;
}

.project-sheet .header .img-wrapper .btn-group {
  position: absolute;
  bottom: 32px;
  right: 32px;
  cursor: pointer;
}

.project-sheet .header .sub-menu-btn {
  background: url(../img/icon-project-header-menu@2x.png) no-repeat right bottom;
  background-size: cover;
}

.members {
  margin-top: 32px;
  display: inline-block;
  width: 100%;
}

.members .block {
  width: 274px;
  height: 232px;
  border-radius: 8px;
  text-align: center;
  position: relative;
  margin-right: 18px;
  float: left;
}

.members .block .avatar {
  margin: 32px auto 16px auto;
}

.members .block .text > .title {
  font-family: Inter-Regular_;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
}

.members .block .text > .email-wrapper .email {
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #00beff;
}

.members .block .text > .email-wrapper img {
  opacity: 0;
}

.members .block:hover .text > .email-wrapper img.copy {
  cursor: pointer;
  opacity: 1;
}

.members .block .text > .position {
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #8a8b8b;
  margin-top: 16px;
}

.files {
  margin-top: 32px;
  width: 100%;
  display: inline-block;
}

.files .block > .title,
.request-subject .block .title {
  font-family: Inter-Regular_;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;

  display: block;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 56.16px;
}

.files .block > .title {
  font-size: 18px !important;
}

.request-subject .block .text-column .title {
  font-size: 14px;
  line-height: 1;
  padding-top: 5px;
  display: block;
}

.file-description {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 16px;
  font-weight: 500;
}

.files .block .meta,
.request-subject .block .meta {
  width: 100%;
}

.files .block .meta {
  line-height: 1.0;
}

.request-subject .block .meta {
  display: inline-block;
  line-height: 1.33;
}


.request-subject .block .meta {
  display: block;
}

.request-subject .block .meta span {
  line-height: 1.2;
}

.mb20 {
  margin-bottom: 20px;
}

.mb50 {
  margin-bottom: 50px;
}

.file-subject-td {
  vertical-align: top;
}

.file-subject-name {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 20px;
}

.file-meta-center {
  text-align: center;
}

.file-category {
  font-family: OpenSans;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #8a8b8b;
}

.file-button {
  border: solid 1px #e0e0e0;
  padding: 0 20px 0 12px;
  border-radius: 25px;
}

.file-detail-button-group {
  justify-content: center;
  display: flex;
}

@media (max-width: 525px) {
  .form-field-wrapper .dropdown-wrapper {
    width: 100px;
  }

  .file-subject-name {
    display: block;
  }
}

@media (max-width: 364px) {
  .file-detail-button-group {
    display: block;
  }
}

.file-detail-button {
  margin: 5px;
}

.file-history {
  cursor: pointer;
}

.file-meta,
.files .block .meta > span,
.request-subject .block .meta > span {
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #8a8b8b;
}

.files .block .upload {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #00beff;
  cursor: pointer;
  display: inline-block;
}

.project-content .block .action-btn {
  width: 24px;
  height: 24px;
  display: inline-block;
  cursor: pointer;
  opacity: 0;
}

.project-content .block:hover .action-btn,
.view .block:hover .action-btn {
  opacity: 1;
}

.project-content .members .block .action-btn {
  position: absolute;
  top: 16px;
  right: 16px;
}

.project-content .actions .action-item .action-btn {
  position: absolute;
  top: 25px;
  right: 16px;
}

.comment-item-wrapper .action-btn {
  position: absolute;
  top: 10px;
  right: 0;
}

/* Light and Dark themes: begin */
.light .project-sheet {
  background-color: #ffffff;
}

.dark .project-sheet {
  background-color: #1c1d1f;
}

.light .project-sheet > .header .title,
.light .project-sheet > .header .description,
.light .project-sheet > .header .description textarea,
.light .project-sheet > .content .block-description,
.light .project-sheet > .content .title {
  color: #1c1d1f;
}

.dark .project-sheet > .header .title,
.dark .project-sheet > .header .description,
.dark .project-sheet > .header .description textarea,
.dark .project-sheet > .content .block-description,
.dark .project-sheet > .content .title {
  color: #ffffff;
}

.light .project-sheet .members .block:hover,
.light .project-sheet .links .block:hover,
.light .project-sheet .files .block:hover {
  background-color: #f5f5f5;
}

.dark .project-sheet .members .block:hover,
.dark .project-sheet .links .block:hover,
.dark .project-sheet .files .block:hover {
  background-color: #2c2f33;
}

.light .more-icon {
  background: url(../img/icon-more@3x.png) no-repeat top left;
  background-size: cover;
}

.modal-content .more-icon {
  background: url(../img/icon-more@3x.png) no-repeat top left;
  background-size: cover;
}

.dark .more-icon {
  background: url(../img/icon-more@3x.png) no-repeat bottom right;
  background-size: cover;
}

.light .tag,
.light .tag-input {
  color: #1c1d1f;
}

.dark .tag,
.dark .tag-input {
  color: #ffffff;
}
/* Light and Dark themes: end */

/* Project link: begin */
.modal-body .sub-title {
  font-family: Inter-Regular_;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #1c1d1f;
  display: block;
  margin-bottom: 4px;
}

.project-link-input-wrapper {
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  padding: 0 16px 2px 16px;
}

.project-link-input-wrapper.focus {
  box-shadow: 0 0 4px 0 rgba(80, 96, 255, 0.48);
  border: solid 1px #5160ff;
}

.project-link-input-wrapper .td-validation-icon img.check {
  display: inline;
}

.project-link-input-wrapper.border-error .td-validation-icon img.check {
  display: none;
}

.project-link-input-wrapper .td-validation-icon img.error {
  display: none;
}

.project-link-input-wrapper.border-error .td-validation-icon img.error {
  display: inline;
}

.project-link-input-tbl,
.project-link-input-readonly-tbl {
  width: 100%;
}

.project-link-input-tbl .td-project-base-url,
.project-link-input-tbl .td-validation-icon,
.project-link-input-readonly-tbl .td-control-btn {
  width: 1%;
  white-space: nowrap;
  text-align: right;
}

.project-link-input-tbl input.project-link {
  width: 100%;
  display: inline-block;
  border: none;
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #5060ff;
}

.project-link-input-tbl.focus {
  border: 1px solid #5160ff;
}

.modal-body {
  padding: 12px 32px 32px 32px;
}

.project-base-url {
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #8a8b8b;
}

.validation-errors {
  margin-top: 8px;
}

.validation-error {
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #e64040;
}

.project-link-input-readonly {
  border-radius: 8px;
  border: solid 1px #e0e0e0;
  background-color: rgba(255, 255, 255, 0.01);
}

.share td.td-project-link-input {
  padding-right: 8px;
}

.share input.project-link,
.share input.collaborator-email {
  border-radius: 8px;
  border: solid 1px #e0e0e0;
  background-color: rgba(255, 255, 255, 0.01);
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1c1d1f;
  height: 40px;
  padding: 8px 16px;
  width: 100%;
}

.share input.project-link {
  caret-color: transparent;
  text-shadow: 0px 0px 1px #fff;
}

.user-role-select-wrapper {
  margin-top: 16px;
}

.user-role-select-wrapper button.btn-outline-grey {
  width: 240px;
  font-weight: 400;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
}

.user-role-select-wrapper table td {
  vertical-align: top;
}

.user-role-select-wrapper .role-description {
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #8a8b8b;
  display: inline-block;
}

.user-role-select-wrapper .td-role-description {
  padding-left: 16px;
}

.user-role-select-wrapper .dropdown-menu {
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  border: none;
  width: 100%;
  padding: 8px;
}

.user-role-select-wrapper .dropdown-menu a {
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  background-color: transparent;
  color: #1c1d1f;
  border-radius: 4px;
}

.user-role-select-wrapper .dropdown-menu a.dropdown-item {
  padding: 4px 16px;
}

.user-role-select-wrapper .dropdown-menu a:hover {
  background-color: rgba(80, 96, 255, 0.08);
}

.details-modal .modal-sub-title {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #1c1d1f;
  padding: 0;
  margin: 16px 0 0 0;
  text-align: left;
}

#project-settings-modal .modal-body {
  padding: 0 32px;
}

#project-header-settings-modal .modal-body {
  padding: 12px 20px 32px 32px;
}

#add-link-modal .form-field-wrapper textarea.description {
  min-height: 64px;
}
/* Project link: end */

/* New team member: begin */
.form-field-wrapper .dropdown-wrapper input.role:focus {
  border: 1px solid #e0e0e0;
}

#add-member-modal .form-field-wrapper .dropdown-wrapper {
  width: 100%;
}

#add-member-modal .form-field-wrapper.avatar {
  margin-bottom: 24px;
}

.form-field-wrapper.avatar img {
  cursor: pointer;
}
/* New team member: end */

/* Add new file : begin */
.file-drop-area img.upload {
  display: block;
  margin: 48px auto 24px auto;
}

.file-drop-area {
  height: 180px;
  border-radius: 8px;
  border: dashed 1px #e0e0e0;
  background-color: rgba(0, 0, 0, 0.03);
}

.file-drop-area {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #8a8b8b;
  margin-bottom: 24px;
}

.file-drop-area .browse {
  color: #5160ff;
  text-decoration: underline;
  cursor: pointer;
}

#file-popup-drop-area-input {
  display: none;
}

.file-drop-area.files-over {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.files-wrapper {
  max-height: 180px;
  overflow-y: scroll;
}

.files-wrapper .file {
  min-height: 64px;
  padding: 12px 16px;
  border-radius: 8px;
  border: solid 1px #e0e0e0;
  background-color: rgba(255, 255, 255, 0.01);
  margin-bottom: 8px;
}

.files-wrapper .file .controll-btn-wrapper {
  display: flex;
}

.tbl-file-content {
  width: 100%;
}

.tbl-file-content .icon,
.tbl-file-content .control {
  width: 1%;
  white-space: nowrap;
}

.tbl-file-content img.control-icon,
.block img.control-icon {
  cursor: pointer;
  display: block;
}

.tbl-file-content img.control-icon.delete {
  margin-left: 10px;
}

.tbl-file-content .body {
  padding: 0 16px;
  position: relative;
}

.tbl-file-content .body .title {
  font-family: Inter-Regular_;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #1c1d1f;
  margin-bottom: 4px;
}

.tbl-file-content .body .size,
.tbl-file-content .progress-status-text {
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #8a8b8b;
}

.tbl-file-content .body .error-wrapper {
  top: 0;
  right: 0;
  position: absolute;
  width: 115px;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
  padding: 0 16px;
}  

.tbl-file-content .body .error-wrapper .error-text {
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #e64040;
  background-color: rgb(255, 255, 255);
  padding: 2px;
  border-radius: 2px;
}

.tbl-file-content .progress {
  height: 4px;
}

.tbl-file-content .progress-status-text {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}

#add-file-modal .modal-body {
  padding-bottom: 16px;
}
/* Add new file : end */


.placeholder-block {
  display: flex;
}

.placeholder-block .icon-wrapper{
  width: 72px;
  height: 72px;
}

.text-wrapper {
  flex-grow: 1;
  padding-left: 16px;
  justify-content: center;
  flex-direction: column;
  display: flex;
}

.text-wrapper .text-row {
  font-size: 12px;
  color: #8a8b8b;
}

.helper-block {
  margin-top: 24px;
}

.helper-block .title {
  font-size: 18px;
  color: #1c1d1f;
}

.helper-block .helper-item {
  font-size: 16px;
  color: #8a8b8b;
}
/* Project builder: end */

/* User profile page: begin */
.custom-switch {
  position: absolute;
  right: 10px;
}

/* Profile form: begin */
.profile-form-wrapper {
  width: 456px;
  margin: 40px auto 0 auto;
  padding: 40px 32px 32px 32px;
}

.profile-form-wrapper .form-field-wrapper .dropdown-wrapper {
  width: 100%;
}

.change-password {
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #5160ff;
  text-decoration: underline;
  cursor: pointer;
}

.profile-form-wrapper .form-footer {
  padding: 0;
}

.profile-form-wrapper .form-footer button:first-child {
  margin-right: 8px;
}
/* Profile form: end */

/* Change password popup: begin */
@media (min-width: 576px) {
  #change-password-modal > .modal-dialog {
    max-width: 456px;
  }
}

#change-password-modal .modal-body {
  padding-bottom: 24px;
}

.form-field-wrapper .icon-input-wrapper {
  height: 40px;
  line-height: 40px;
}

.form-field-wrapper input.eyed-password {
  padding: 4px 40px 4px 16px;
  float: left;
}

.form-field-wrapper .field-info {
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #8a8b8b;
  margin-top: 8px;
}

.password-icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-left: -40px;
  margin-top: -3px;
  background: url("../img/icon-eye-password@2x.png") no-repeat top left;
  background-size: cover;
  vertical-align: middle;
  cursor: pointer;
}

.password-icon.show {
  background: url("../img/icon-eye-password@2x.png") no-repeat bottom right;
  background-size: cover;
}
/* Change password popup: end */

/* User profile page: end */

/* LoginButton : begin */
.navbar.login {
  padding: 14px 32px;
}

.navbar.login .btn-outline-primary {
  height: 40px;
  margin-left: 24px;
  font-family: Inter-Regular_;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #5160ff;
}

.navbar.login .btn-outline-primary:hover {
  color: #ffffff;
}

.login-header-text {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #8a8b8b;
}

.login-page-content {
  margin: 100px auto 20px auto;
}

.basic-form {
  padding: 80px;
  max-width: 616px;
  position: relative;
}

.basic-form .form-title {
  font-family: Inter-Regular_;
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #1c1d1f;
  margin-bottom: 72px;
}

.basic-form .form-footer {
  padding: 0;
  display: flex;
  margin-top: 40px;
}

.w-150 {
  width: 150px;
}

.w-220 {
  width: 220px;
}

.basic-form .form-footer button.google {
  background-color: #d64d40;
}

.basic-form .form-footer button.action {
  margin-left: 8px;
}

img.google {
  margin-top: -3px;
}

.validation-errors.summary {
  margin-top: 0;
}

.basic-form.popup {
  padding: 48px 48px 80px 48px;
  text-align: center;
}
/* LoginButton : end */

/* Project view: begin */
.project-view .page-header {
  height: auto;
  width: 100%;
  z-index: 100;
}

.project-gradient-1 {
  background: rgb(196,226,143);
  background: linear-gradient(90deg, rgba(196,226,143,1) 0%, rgba(143,226,160,1) 100%);
}

.project-gradient-2 {
  background: rgb(216,117,230);
  background: linear-gradient(90deg, rgba(216,117,230,1) 0%, rgba(188,108,37,1) 100%);
}

.project-gradient-3 {
  background: rgb(88,213,224);
  background: linear-gradient(90deg, rgba(88,213,224,1) 0%, rgba(88,145,224,1) 100%);
}

.project-gradient-4 {
  background: rgb(209,101,208);
  background: linear-gradient(90deg, rgba(209,101,208,1) 0%, rgba(121,101,209,1) 100%);
}

.project-gradient-5 {
  background: rgb(226,120,165);
  background: linear-gradient(90deg, rgba(226,120,165,1) 0%, rgba(237,191,126,1) 100%);
}

.project-gradient-6 {
  background: rgb(118,231,209);
  background: linear-gradient(90deg, rgba(118,231,209,1) 0%, rgba(223,164,231,1) 100%);
}

.project-view .page-header .nav-item span {
  font-family: Inter-Regular_;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #ffffff;
  cursor: pointer;
}

.project-view .page-header ul.navbar-nav {
  margin-right: 92px;
  flex-direction: row;
}

.project-view .page-header ul.navbar-nav li {
  display: inline;
  margin-left: 24px;
}

.project-view .page-header ul.navbar-nav li.active {
  border-bottom: 4px solid #ffffff;
}

.project-view .page-header ul.navbar-nav li:first-child {
  margin-left: 0;
}

.project-view {
  background-color: #ffffff !important;
}

@media (max-width: 658px) {
  .project-view .header .content {
    padding: 20px 20px 0 20px;
  }
}

@media (max-width: 420px) {
  .project-view .page-header ul.navbar-nav {
    margin-right: 0px;
    /*flex-direction: row;*/
  }

  .project-view .header .content {
    padding: 20px 20px 0 20px;
  }
}

.project-view .project-sheet {
  max-width: 940px;
  margin: 0 auto;
  padding-top: 130px;
}

.project-sheet .group-wrapper .empty {
  margin: 32px 0;
  width: 100%;
  table-layout: fixed;
}
.project-sheet .group-wrapper .empty tr td:first-child {
  width: 72px;
  white-space: nowrap;
}

.project-sheet .group-wrapper .empty .text {
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #8a8b8b;
  width: 100%;
  padding: 17px;
}

.project-view .project-content .project-sheet .group-wrapper h4 {
  color: #8a8b8b !important;
}

.project-view .project-content .project-sheet > .header .description {
  font-family: Inter-Regular_;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #8a8b8b;
}

.project-view .project-content .links .block:hover {
  cursor: pointer;
}

.project-view .project-content .content.files .navbar {
  padding: 0;
  margin-top: 34px;
}

.login-popup {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  width: 216px;
  padding: 24px;
  position: fixed;
  right: 16px;
  top: 45%;
  background-color: #ffffff;
  border-radius: 8px;
}

.login-popup .title {
  font-family: Inter-Regular_;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #1c1d1f;
}

.login-popup .description {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #1c1d1f;
  margin-bottom: 32px;
}

.login-popup button.login,
.login-popup button.create-account {
  width: 100%;
  border-radius: 8px;
  border: none;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  outline: none;
  padding: 10px 24px;
}

.login-popup button.login {
  margin-bottom: 8px;
}

.login-popup button.login:focus,
.login-popup button.create-account:focus {
  border: none;
}

.login-popup button.login {
  background-color: #5060ff;
}

.login-popup button.create-account {
  background-color: #39cc3c;
}
/* Project view: end */



/* Profile page: begin */

.profile-form {
  margin-top: 40px;
  padding: 40px 32px 32px 32px;
  max-width: 456px;
  position: relative;
}

.profile-form .form-title {
  font-family: Inter-Regular_;
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #1c1d1f;
  margin-bottom: 72px;
}

.profile-form .form-footer {
  padding: 0;
  display: flex;
  margin-top: 40px;
}

.profile-form {
  padding-top: 40px;
}

.profile-form .empty-img {
  width: 72px;
  height: 72px;
  background: url(../img/icon-add-big@2x.png) no-repeat left top;
  background-size: cover;
  margin-bottom: 24px;
  cursor: pointer;
}

.profile-form .form-field-wrapper .dropdown-wrapper .icon-dropdown {
  margin-top: 0 !important;
}

.profile-form .change-password {
  color: #5160ff;
  text-decoration: underline;
  text-align: left;
  font-size: 12px;
}

.profile-form .form-footer .btn-outline-grey {
  margin-right: 8px;
}

.profile-img-wrapper .wrap-link {
  display: table;
  text-decoration: none;
}
/* Profile page: end */


/* Server error pages: begin */

.server-error-wrapper {
  margin-top: 108px;
  text-align: center;
}

.server-error-wrapper .title {
  font-family: Inter-Regular_;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #1c1d1f;
  margin-top: 72px;
}

.server-error-wrapper .sub-title {
  font-family: Inter-Regular_;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #1c1d1f;
  margin-top: 16px;
}

/* Server error pages: end */

/* Request Approval/Feedback */
.request-subject .block {
  width: 100%;
  padding: 12px 8px 12px 12px;
  height: 72px;
  border-radius: 8px;
  margin-right: 18px;
  border: solid 1px #e0e0e0;
}

.request-subject .block table {
  width: 100%;
}

.request-subject .block table {
  padding: 0;
  margin: 0;
}

.request-subject .block .icon-column {
  width: 48px;
}

.request-subject .block .text-column {
  padding-left: 16px;
}

.request-subject .profile-img {
  width: 72px;
  height: 72px;
  font-size: 24px;
}

.request-subject .member-icon-wrapper {
  margin-left: -8px;
}

.members-wrapper {
  height: 40px;
}

.members-wrapper .member-icon {
  height: 40px;
}

.request-subject .member-icon-wrapper .member-icon,
.members-wrapper .profile-img {
  border-radius: 8px;
  margin-left: 8px;
  position: relative;
  display: inline-block;
  height: 100%;
  overflow: hidden;
}

.request-subject .member-icon-wrapper .member-icon:hover .profile-img {
  -webkit-filter: grayscale(100%) blur(2px);
  filter: grayscale(100%) blur(2px);
  background-color: #000;
  opacity: 0.2;
}

.request-subject .member-icon-wrapper .member-icon .delete-icon {
  background: url(../img/icon-delete-light@2x.png) no-repeat left top;
  background-size: cover;
  width: 24px;
  height: 24px;
  cursor: pointer;
  vertical-align: middle;
  margin: 0 auto;
}

.request-subject .member-icon-wrapper .delete-icon-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  line-height: 72px;
  text-align: center;
}

.request-subject .member-icon-wrapper .member-icon .delete-icon {
  display: none;
}

.request-subject .member-icon-wrapper .member-icon:hover .delete-icon {
  display: inline-block;
}

.member-search-wrapper {
  position: relative;
}

.member-search-wrapper .dropdown-menu {
  position: absolute;
  border-radius: 8px;
  width: 100%;
  font-size: 15px;
  display: block;
}

.member-search-wrapper .dropdown-menu .dropdown-item {
  padding: 5px;
}

.member-search-wrapper .dropdown-menu .dropdown-item:hover {
  color: #212529;
}

.member-search-wrapper .dropdown-menu .dropdown-item:active .member-full-name {
  color: #ffffff;
}

.member-search-wrapper .dropdown-menu .profile-img {
  width: 30px;
  height: 30px;
  font-size: 15px;
  border-radius: 5px;
}

.member-search-wrapper .dropdown-menu .member-full-name {
  margin-left: 5px;
}

/* Request Approval/Feedback */


/* Actions */
.actions .action-item {
  width: 424px;
  height: 200px;
  display: inline-block;
  margin-top: 24px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}

.actions .action-item:nth-child(2n) {
  margin-left: 28px;
}


.actions .action-item.green {
  background-color:rgb(54, 178, 126, 0.08);  
}

.actions .action-item.orange {
  background-color: rgb(254, 116, 82, 0.08);
}

.actions .action-item .status {
  height: 8px;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.actions .action-item.green .status {
  background-color: #36b27e;
}

.actions .action-item.orange .status {
  background-color: #fe7452;
}

.actions .action-item .title {
  margin: 24px 93px 12px 24px;
  font-family: Inter-Regular_;
  font-size: 18px;
  font-weight: 600;
}

.actions .action-item .description {
  margin: 12px 24px;
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1c1d1f !important;

  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  max-height: 72px;
  min-height: 72px;
}

.actions .action-item .member-icon-wrapper {
  margin: 12px 24px;
  display: inline-block;
}

.actions .action-item .member-icon-wrapper .member-icon {
  margin-left: 8px;
}

.actions .action-item .member-icon-wrapper .member-icon:first-child {
  margin-left: 0;
}

.actions .action-item .action-footer {
  justify-content: space-between;
  display: flex;
  margin: 12px 24px;
  max-width: 100%;
}


.actions .action-item button.action {
  font-family: Inter-Regular_;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border-radius: 8px;
    background-color: #5060ff;
    padding: 6px 12px;
    cursor: pointer;
}

.actions .action-item button.bordered {
  color: #5060ff;
  background-color: transparent;
  border: 1px solid #e0e0e0;
  margin-right: 8px;
  cursor: pointer;
}

.actions .action-item .download-link-wrapper {
  align-items: center;
  cursor: pointer;
}

.actions .action-item .download-link-wrapper .download {
  display: flex;
  align-items: center;
  flex-shrink: 1;
}

.actions .action-item .download-link-wrapper .download .file-name {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  margin-right: 5px;

  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  max-height: 20px;
  min-height: 20px;

  word-break: break-all;
}

.actions .action-item .member-icon-wrapper .profile-img,
.members-wrapper .member-icon .profile-img {
  background-color: #ffe600;
  font-size: 14px;
  font-weight: 600;
  min-height: 40px;
}

.members-wrapper .member-icon .profile-img {
  line-height: 40px;
}

.members-wrapper .member-icon.counter .profile-img {
  background-color: #e0e0e0 !important;
  cursor: pointer;
}

.member-dropdown-wrapper .dropdown-menu .dropdown-item {
  padding: 0.35rem 1rem 0.35rem 0.5rem;
}

.member-dropdown-wrapper .dropdown-menu .dropdown-item .profile-img {
  height: 40px;
  width: 40px;
  text-align: center;
  vertical-align: center;
  line-height: 40px;
  margin-left: 0;
}

.member-dropdown-wrapper .dropdown-menu .dropdown-item:focus,
.member-dropdown-wrapper .dropdown-menu .dropdown-item:active {
  background-color: #f8f9fa;
}

.member-dropdown-wrapper .dropdown-menu .dropdown-item .profile-img {
  vertical-align: center;
}

.member-dropdown-wrapper .dropdown-menu .dropdown-item .member-full-name {
  margin-left: 8px;
}

.download-img {
  margin-top: -2px;
  margin-right: 8px;
}

.file-upload {
  margin-left: 20px;
  color: #1c1d1f !important;
}

.file-upload-icon {
  background: url(../img/icon-actions-black@2x.png) no-repeat left top;
  background-size: cover;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  margin-right: 5px;
}

.action-buttons-wrapper {
   flex-direction: row;
   display: flex;
   align-items: center;
   justify-content: center;
   vertical-align: middle;
   flex-grow: 0;
   flex-shrink: 0;
}

@media (max-width: 655px) {
  .action-buttons-wrapper {
    justify-content: left;
  }

  #files {
    display: block;
  }

  #links {
    display: block;
  }
}

.header-btn-wrapper {
    display: flex;
    align-items: center;
}

.modal-content .header-btn-wrapper {
  padding-right: 65px;
}

.edit-action .profile-img {
  width: 40px;
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  background-color: #ffe600;
}

.edit-action .member-icon-wrapper .delete-icon-wrapper {
  line-height: 40px;
}

.edit-action .description textarea {
  font-family: Inter;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  font-size: 16px;
}

.edit-action .btn.disabled,
.action-buttons-wrapper .btn.action.disabled {
  background-color: #d6d6d6;
  cursor: default;
}

.action-buttons-wrapper .btn.action.bordered.disabled {
  color: #d6d6d6;
  background-color: #ffffff; 
}

.edit-action .file-drop-area {
  height: 118px;
}

.edit-action .file-drop-area img.upload {
  margin: 18px auto 24px auto;
}

.edit-action .block .control {
  width: 1%;
}

.comments-textarea-wrapper {
  position: relative;
  margin-bottom: 16px;
}

.comments-textarea-wrapper textarea.comments-textarea {
  display: block;
  min-height: 58px;
  padding: 4px 50px 4px 10px;
}

.comments-send-btn {
  width: 40px;
  height: 40px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 8px;
  right: 8px;
  cursor: pointer;
}

.comment-item-wrapper {
  display: flex;
  padding: 16px 0;
}

.comment-text-wrapper {
  width: 100%;
  padding-left: 16px;
}

.comment-text-content {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #1c1d1f;
  margin-top: 3px;
}

.comment-item-wrapper {
  border-top: 1px solid #f0f0f0;
}

.comment-item-wrapper:first-child {
  border: none;
}


.comments-send-btn:hover {
  border: 1px solid #5060ff;
}

.comment-text-header {
  display: flex;
  justify-content: space-between;
}

.comment-text-header .member-name {
  font-family: Inter-Regular_;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #1c1d1f;

  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  max-height: 20px;
  min-height: 20px;
}

.comment-text-header .comment-time {
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  color: #8a8b8b;
}

.action-modal-body {
  overflow-y: auto;
}

@media (min-width: 576px) {
  .modal-dialog {
    margin: 0 auto;
  }
}

.edit-action .modal-dialog {
  height: 100% !important;
  display: flex;
  align-items: center;
}

.edit-action .modal-content {
  max-height: 90%;
}

.mh320 {
  max-height: 320px;
}

.modal-content .comment-item-wrapper:hover .more-icon-wrpapper {
  background-color: #f2f2f2;
  border-radius: 3px;
  display: block;
  width: 28px;
  height: 28px;
  text-align: center;
  padding-top: 2px;
}

.modal-content .sub-menu .dropdown-item {
  color: #1c1d1f !important;
}

.modal-content .sub-menu .dropdown-item:hover {
  color: #5160ff !important;
}

/* Actions */

/* Home */
.home-page-layout-wrapper {
  background-color: #f5f5f5;
    font-family: Inter, sans-serif;
    color: #333;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    text-align: center;
    cursor: auto;
}

.header.home {
  z-index: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 24px 48px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: transparent;
  background-clip: border-box;
}

@media (max-width: 600px) {
  .header.home {
    display: block;
  }
}

.w-button {
  display: inline-block;
  padding: 9px 15px;
  background-color: #3898EC;
  color: white;
  border: 0;
  line-height: inherit;
  cursor: pointer;
  text-decoration: none;
  border-radius: 0;
  margin-left: 8px;
}

.button {
  padding: 16px 32px;
  border-style: solid;
  border-width: 1px;
  border-color: #5060ff;
  border-radius: 8px;
  background-color: #5060ff;
  -webkit-transition: box-shadow 200ms ease, background-color 200ms ease, -webkit-transform 200ms ease;
  transition: box-shadow 200ms ease, background-color 200ms ease, -webkit-transform 200ms ease;
  transition: box-shadow 200ms ease, background-color 200ms ease, transform 200ms ease;
  transition: box-shadow 200ms ease, background-color 200ms ease, transform 200ms ease, -webkit-transform 200ms ease;
  font-family: Inter, sans-serif;
  color: #fff;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  cursor: pointer;
}


.button:hover {
  border-color: #5968ff;
  background-color: #5968ff;
  box-shadow: 0 8px 12px -4px rgba(80, 96, 255, 0.72);
  -webkit-transform: translate(0px, -2px);
  -ms-transform: translate(0px, -2px);
  transform: translate(0px, -2px);
}

.button.secondary {
  border-style: solid;
  border-width: 1px;
  border-color: #5060ff;
  background-color: transparent;
  color: #5060ff;
  text-transform: uppercase;
  text-decoration: none;
}

.button.secondary:hover {
  background-color: #5968ff;
  color: #fff;
}

.button.blue {
  background-color: #5968ff;
  color: #fff;
}

.button.secondary:active {
  border-color: #4958eb;
  background-color: #4958eb;
}

.h1 {
  margin-top: 0px;
  margin-bottom: 16px;
  color: #333;
  font-size: 96px;
  line-height: 1.1;
  font-weight: bold;
  font-family: Inter-Regular_;
  text-align: center;
}

@media (max-width: 400px) {
  .h1 {
    font-size: 72px;
  }
}

@media (max-width: 200px) {
  .h1 {
    font-size: 36px;
  }
}

.gradient {
  background-image: linear-gradient(58deg, #5060ff, #333);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.d1 {
  margin-bottom: 48px;
  padding-right: 88px;
  padding-left: 88px;
  color: #8a8b99;
  font-size: 24px;
  font-weight: 600;
}

.section {
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
  float: none;
  clear: none;
}

.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}

.w-container:before, .w-container:after {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}

.section.image {
  display: block;
  margin-right: auto;
  margin-bottom: -80px;
  margin-left: auto;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

.image {
  max-height: none;
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.image-2.special._2 {
  left: auto;
  top: 755.62px;
  right: 124px;
  width: 320px;
}


.image-2.special {
  position: absolute;
  left: -79px;
  top: 1476.62px;
  z-index: -1;
  overflow: visible;
  width: 470px;
  max-width: none;
  float: right;
  clear: none;
  opacity: 0.08;
  -o-object-fit: fill;
  object-fit: fill;
}

.image-2 {
  position: static;
  overflow: hidden;
  float: none;
  clear: none;
  -o-object-fit: fill;
  object-fit: fill;
}

.w-clearfix:before, .w-clearfix:after {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}

.grid {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: stretch;
  -webkit-justify-content: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  justify-items: stretch;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: start;
  -ms-flex-line-pack: start;
  align-content: start;
  -ms-grid-row-align: auto;
  align-self: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  -ms-grid-rows: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.feature {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  padding: 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.04);
  -webkit-perspective: 1039px;
  perspective: 1039px;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  -webkit-transition: box-shadow 350ms ease;
  transition: box-shadow 350ms ease;
  cursor: default;
}

.feature:hover {
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.12);
}

#w-node-07520ec416fe-34b42f94 {
  -ms-grid-column-align: center;
  justify-self: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

#w-node-a0cf17f7f3ae-34b42f94 {
  -ms-grid-column-align: center;
  justify-self: center;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

#w-node-b188b8a1697e-34b42f94 {
  -ms-grid-column-align: center;
  justify-self: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

#w-node-18905d2fcdeb-34b42f94 {
  -ms-grid-column-align: center;
  justify-self: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

#w-node-db8f502485e7-34b42f94 {
  -ms-grid-column-align: center;
  justify-self: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

#w-node-c088fc103755-34b42f94 {
  -ms-grid-column-align: center;
  justify-self: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

.p3 {
  margin-bottom: 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #8a8b99;
  font-size: 16px;
  text-align: center;
}

.h3 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  font-size: 24px;
  text-align: center;
}

.content.space {
  margin-top: 56px;
}

.home.content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  text-align: center;
}

.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  /* grid-template-columns: 1fr 1fr; */
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

strong {
  font-weight: bold;
}

.d2 {
  padding-right: 88px;
  padding-left: 88px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #8a8b99;
  font-size: 20px;
  line-height: 1.3;
  text-align: center;
}

.animated-icon {
  display: block;
  padding: 24px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  -webkit-transform: perspective(1000px);
  transform: perspective(1000px);
  -webkit-transition: -webkit-transform 400ms ease;
  transition: -webkit-transform 400ms ease;
  transition: transform 400ms ease;
  transition: transform 400ms ease, -webkit-transform 400ms ease;
  -o-object-fit: fill;
  object-fit: fill;
}

.animated-icon:hover {
  -webkit-transform: translate(0px, 0px) scale(1.2) rotate(-9deg) translate(0px, -5px) perspective(1020px);
  transform: translate(0px, 0px) scale(1.2) rotate(-9deg) translate(0px, -5px) perspective(1020px);
}

.icons-grid {
  margin-right: 10%;
  margin-left: 10%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}

@media (max-width: 786px) {
  .icons-grid {
    grid-template-columns: repeat(auto-fit, minmax(96px, 1fr));
  }
}

.banner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 16px;
  background-color: #5060ff;
  -webkit-transition: box-shadow 400ms ease;
  transition: box-shadow 400ms ease;
}

.div-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 48px 0px 48px 64px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  color: #fff;
}

@media (max-width: 767px) {
  .banner {
    display: block;
  }

  .div-block-2 {
    display: block;
    padding: 0px;
  }
}

.p2 {
  margin-bottom: 32px;
  color: hsla(0, 0%, 100%, 0.72);
  font-size: 20px;
  font-weight: 400;
  text-align: left;
}

.button.white {
  border-color: #fff;
  background-color: #fff;
  color: #5060ff;
  text-transform: uppercase;
  text-decoration: none;
}

.h2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 8px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 40px;
  text-align: center;
  font-weight: bold;
  font-family: Inter-Regular_;
}

.footer.home {
  margin-top: 80px;
  padding-top: 32px;
  padding-bottom: 32px;
  background-color: #fff;
  text-align: center;
}

.profile-image-dropdown-menu{
  transform: translate3d(-124px, 42px, 0px) !important;
}

.dashboard {
  height: 100%;
  display: flex;
}

.dashboard-projects-header {
  width: 368px;
  height: 80px;
}

.dashboard-projects {
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.dashboard-projects::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.dashboard-projects-logo {
  margin-top: 24px;
  margin-left: 19px;
  width: 125px;
  height: 45px;
  object-fit: contain;
}

.dashboard-profile-box {
  width: 320px;
}

.dashboard-profile {
  width: 56px;
  height: 56px;
  margin-left: 16px;
  margin-bottom: 16px;
  margin-right: 16px;
  border-radius: 50%;
  cursor: pointer;
}

.dashboard-profile-name {
  font-family: Inter;
  font-size: 18px;
  line-height: 24px;
  color: #2E2E2E;
  max-width: 220px;
}

.dashboard-profile-email {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #AEAEAE;
  max-width: 220px;
}

.text-crop-dots {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.dashboard-line {
  width: 0px;
  left: 368px;
  top: 0px;
  bottom: -435px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}

.dashboard-project-line {
  width: 240px;
  height: 0px;
  left: 24px;
  top: 170px;
  border-top: 0.5px solid rgba(0, 0, 0, 0.16);
  margin: auto;
}

.dashboard-project-item {
  width: 288px;
  height: 56px;
  margin: auto;
  display: flex;
  cursor: pointer;
  border-radius: 10px;
}

.dashboard-project-item:hover:not(.dashboard-project-active) {
  background-color: #f5f5f5;
}

.dashboard-project-icon-wrapper {
  width: 32px;
  height: 32px;
  object-fit: contain;
  border-radius: 50%;
  margin-top: 12px;
  margin-left: 24px;
  display: flex;
  justify-content: center;
}

.dashboard-project-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin: auto;
}

.dashboard-project-icon-header {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin: auto;
}

.dashboard-project-text {
  margin-top: 8px;
  margin-left: 24px;
  width: 174px;
}

.dashboard-project-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.dashboard-project-active .dashboard-project-title {
  color: #FFFFFF;
}

.dashboard-header-title {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 40px;
  color: #2E2E2E;
}

.dashboard-project-user {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.03em;
}

.dashboard-project-message {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #757D8A;
}

.dashboard-project-active .dashboard-project-message {
  color: #FFFFFF;
}

.dashboard-project-line-text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.dashboard-project-active {
  background: #6577FB;
  border-radius: 10px;
}

.dashboard-new-project {
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
}

.dashboard-new-project-btn {
  width: 100%;
  height: 40px;
  margin-left: 16px;
  margin-right: 16px;
  background: #F4F7FA;
  border-radius: 10px;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #6577FB;
}

.dashboard-sidebar {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dashboard-header {
  height: 136px;
}

.dashboard-header-name {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 40px;
  color: #2E2E2E;
}

.dashboard-settings-header-name {
  display: flex;
  width: 500px;
  margin-left: 40px;
  height: 64px;
  line-height: 64px;
  align-items: center;
}

.dashboard-header-icon {
  width: 40px;
  height: 40px;
  object-fit: contain;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.dashboard-project-setting-icon {
  width: 64px;
  height: 64px;
  object-fit: contain;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.dashboard-header-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-left: 8px;
}

.dashboard-menu-item {
  height: 40px;
  align-items: center;
  padding: 5px 30px;
  border-radius: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #6577FB;
  display: flex;
  cursor: pointer;
}

.dashboard-menu-item-active {
  background: rgba(0, 0, 0, 0.05);
}

.dashboard-menu-item-text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}

.dashboard-menu-checked-icon {
  width: 9px;
  height: 8px;
  margin-right: 2px;
}

.dashboard-menu-item-notification {
  width: 4px;
  height: 4px;
  background: #FF6A6A;
  border-radius: 50%;
  margin: 0px 4px;
}

.dashboard-project-item-notification {
  width: 8px;
  height: 8px;
  background: #FF6A6A;
  border-radius: 50%;
  margin: auto;
}

.dashboard-dots-menu {
  width: 24px;
  height: 24px;
}

.v-hidden {
  visibility: hidden;
}

.docs-sort-by {
  float: left;
  margin-right: 40px;
  padding-top: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #757D8A;
  cursor: pointer;
}

.docs-view-mode {
  float: right;
  margin-right: 40px;
  width: 16px;
  height: 16px;
}

.docs-list-header {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #2E2E2E;
  background: #F8F8F8;
  border-radius: 8px;
  height: 48px;
  position: sticky;
  top: 0;
}

.docs-header-comments-col {
  text-align: right;
}

.notification-big {
  width: 8px;
  height: 8px;
  background: #FF6A6A;
  border-radius: 50%;
  margin-left: 15px;
  margin-top: 5px;
}

table.dashboard-docs-tmp td {
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.dashboard-docs-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.dashboard-docs-text-grey {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);
}

.ml4 {
  margin-left: 4px
}

.ml8 {
  margin-left: 8px
}

.ml10 {
  margin-left: 10px
}

.ml16 {
  margin-left: 16px
}

.ml40 {
  margin-left: 40px
}

.dashboard-docs-file {
  width: 24px;
  height: 24px;
  border-radius: 2px;
}

.dashboard-docs-line {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}

table.dashboard-docs tr {
  height: 48px;
}

.dashboard-doc-thumbnails {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 24px;
}

.dashboard-doc-thumbnail-item {
  border: 1px solid rgba(0, 0, 0, 0.11);
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
}

.dashboard-doc-thumbnail-img-wrapper {
  width: 100%;
  height: 80%;
  aspect-ratio: 7 / 6;
  display: flex;
  justify-content: center;
}

.dashboard-doc-thumbnail-img {
  display:block;
  margin:auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.dashboard-doc-thumbnail-body {
  display: flex;
  justify-content: space-between;
  height: 56px;
  padding-top: 15px;
  border-top: 0.5px solid rgba(0, 0, 0, 0.16);
}

.content-box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.dashboard-docs-list {
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-left: 24px;
  margin-right: 24px;
}
.dashboard-docs-list::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.doc-details {
  margin-left: 40px;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.doc-details::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.doc-details-img {
  width: 328px;
  height: 328px;
  border-radius: 8px;
  text-align: center;
  margin: auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.dashboard-line-h {
  width: 100%;
  height: 0px;
  border-top: 0.5px solid rgba(0, 0, 0, 0.16);
}

.dashboard-doc {
  height: 100%;
  display: grid;
  grid-template-columns: 3fr 0.1fr 1fr;

  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.dashboard-doc::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.doc-comments {
  margin-top: auto;
  display: flex;
  flex-direction: column-reverse;

  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.doc-comments::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.doc-comment-box {
  border-radius: 8px;
  border: 0.5px solid rgba(0, 0, 0, 0.16);
  padding: 10px;
}

.doc-comments-sidebar {
  height: 100%;
  display: flex;
  flex-direction: column;

  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.doc-comments-sidebar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.doc-comment-input {
  display: flex;
  width: 100%;
  padding: 4px;
  padding-left: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 16px;
  margin-bottom: 20px;
}

.doc-comment-textarea {
  width: 100%;
  height: 30px;
  background: none;
  border: 0;
  padding: 0;
  outline: none;
  overflow: auto;
  box-shadow: none;
  resize: none;
}

.doc-comment-send-btn {
  height: 17px;
  width: 20px;
  cursor: pointer;
}

.doc-comment-box .btn-group.action-btn {
  opacity: 0;
}

.doc-comment-box:hover .more-icon-wrpapper {
  background-color: #f2f2f2;
  border-radius: 3px;
  display: block;
  width: 28px;
  height: 28px;
  text-align: center;
  padding-top: 2px;
}

.doc-comment-box:hover .btn-group.action-btn {
  opacity: 1;
}

doc-comment-box:hover .more-icon {
  opacity: 1;
  cursor: pointer;
}

.doc-comment-box:hover {
  position: relative;
}

.doc-comment-box:hover .action-btn {
  position: absolute;
  top: 10px;
  right: 0;
}

.doc-comment-box .action-btn {
  position: absolute;
  top: 10px;
  right: 0;
}

.doc-comment-box .more-icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(../img/icon-more@3x.png) no-repeat top left;
  background-size: cover;
}

.doc-comment-edit {
  width: 100%;
  border-radius: 8px;
  border: 0.5px solid rgba(0, 0, 0, 0.16);
}

.plus-btn {
  position: absolute;
  width: 64px;
  height: 64px;
  right: 40px;
  bottom: 40px;
  background: #5266FB;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.plus-btn:hover {
  background-color: #3f4ccc;
}

.margin-auto {
  margin: auto;
}

.doc-add-menu {
  position: absolute;
  right: 40px;
  bottom: 40px;
  padding: 8px 12px;
  width: 160px;
  height: 90px;
  background: #FFFFFF;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
}

.doc-add-menu-icon {
  width: 10px;
}

.doc-add-menu-text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.profile-close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.profile-settings {
  background: #FCFCFC;
  border-radius: 0px 0px 8px 8px;
}

.profile-account-picture {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.ml24 {
  margin-left: 24px;
}

.profile-account-text-bold {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.profile-account-text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.profile-account-text-plan {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
}

.profile-account-text-action {
  color: #5266FB;
  cursor: pointer;
}

.profile-account-log-out {
  color: #FF6A6A;
  text-color: #FF6A6A;
  cursor: pointer;
}

.profile-change-text {
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.invis-scrollbar {
  height: 100%;
  display: flex;
  flex-direction: column;

  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.invis-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.docs-text-grey-bold {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.3);
}

.doc-sort-menu {
  z-index: 100;
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  background: #FFFFFF;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
}

.doc-context-menu-item {
  text-align: left;
}

.doc-context-menu-item:hover {
  color: #5160ff;
}

.doc-details-context-menu-item {
  text-align: right;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.4);
}

.doc-details-context-menu-item-remove {
  text-align: right;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: rgba(206, 24, 15, 0.54);
}

.doc-details-context-menu-item-remove:hover {
  color: rgba(206, 14, 0, 0.68);
}

.doc-details-menu {
  transform: translate3d(-110px, 0px, 0px);
}

.doc-project-menu {
  transform: translate3d(-180px, 0px, 0px);
  width: 200px;
}

.doc-thumbnail-text {
  display: block;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-height: 20px;
  margin-left: 20px;
  max-width: 150px;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #2E2E2E;
}

.dashboard-header-project-details {
  margin-left: auto;
}

.project-settings-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.project-settings-desc {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #5266FB;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.project-users-desc {
  width: 284px;
  height: 100%;
  margin-right: 40px;
  padding: 40px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

.project-role-name {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
}

.project-role-desc {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.3);
}

.project-user-header {
  display: flex;
  margin-left: 40px;
  height: 40px;
  line-height: 40px;
  align-items: center;
}

.project-user-icon {
  width: 40px;
  height: 40px;
  object-fit: contain;
  border-radius: 50%;
}

.project-user-name {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.project-user-desc {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.3);
}

.edit-btn {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 0px;
  bottom: 0px;
  background: #5266FB;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.project-invite-user {
  width: 160px;
}

.invite-input {
  border: 0.5px solid rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 40px;
  color: #000000;
}

.cancel-approve {
  border: solid 1px #e0e0e0;
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
}

.cancel-approve:hover {
  color: rgb(0, 0, 0);
}

.project-background-color {
  background-color: #FFF4CB;
}

.word-break {
  word-wrap: break-word;
}

.project-background-wrapper {
  display: flex;
  margin-left: 28px;
}

.project-icon-color {
  width: 24px;
  height: 24px;
  margin-left: 12px;
  border-radius: 50%;
  cursor: pointer;
}

.project-icon-color-active {
  width: 28px;
  height: 28px;
  border: solid 4px rgba(131, 132, 151, 0.2);
}

.project-icon-wrapper {
  overflow-y: scroll;
  padding: 12px;
  width: 100%;
  height: 140px;
  border: solid 1px rgba(131, 132, 151, 0.2);
  border-radius: 8px;

  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-gap: 12px;
}

.project-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.project-icon-div {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.project-icon-active {
  width: 32px;
  height: 32px;
  padding: 2px;
  background-color: rgba(151, 4, 24, 0.2);
  border-radius: 50%;
  text-align: center;
}

.project-icon-popup {
  z-index: 100;
  position: absolute;
  background: #FFFFFF;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  width: 350px;
  padding: 20px;
}

.project-icon-wrapper {
  overflow-y: scroll;
  padding: 12px;
  width: 100%;
  height: 180px;
  border: solid 1px rgba(131, 132, 151, 0.2);
  border-radius: 8px;

  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-gap: 12px;
}

.project-icon-update-btn {
  height: 40px;
  background: #F4F7FA;
  border-radius: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #6577FB;
}

.project-icon-update-btn:hover {
  color: rgb(0, 0, 0);
}

.external-project-logo {
  width: 200px;
  height: 200px;
  object-fit: contain;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin: 49px auto auto;
}

.external-project-icon {
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin: auto;
}

.external-project-name {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 16px;
  margin-top: 40px;
  margin-left: 40px;
  margin-right: 40px;
  width: 288px;
  text-align: center;
  color: #000000;
}

.external-project-description {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin-top: 32px;
  margin-left: 40px;
  margin-right: 40px;
  width: 288px;
  text-align: center;
  color: rgba(0, 0, 0, 0.3);
}

.board-add-card-input {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border: none;
}

.board-add-card-input-desc {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  border: none;
}

.board-add-card {
  background-color: #5266FB;
  border-radius: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  border: none;
  padding: 4px 16px;
}

.board-add-card:hover {
  background-color: #4c5fe9;
}

.board-add-card-cancel {
  background-color: #FFFFFF;
  border-radius: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #2E2E2E;
  border: none;
  padding: 4px 16px;
}

.board-add-card-cancel:hover {
  background-color: #f5f5f5;
}

.board-lane-add-btn {
  background-color: #F1F2F3;
  border-radius: 8px;
  border: none;
}

.board-lane-add-btn:hover {
  background-color: #eaebec;
}

.board-lane-add-btn-text {
  padding: 10px;
}

.board-add-list-text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  margin-left: 8px;
  color: #868686;
}

.board-div {
  width: 500px;
  overflow-x: scroll
}

.react-trello-card {
  border-radius: 3px;
  box-shadow: 0 1px 0 #091e4240;
}

.grey-italic {
  font-family: Inter;
  font-style: italic;
  font-weight: 600;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.3);
}

.board-card-detail-textarea {
  overflow: hidden;
  overflow-wrap: break-word;
  resize: none;
  height: 108px;
  width: 100%;
  border-color: #091e4240;
}

.board-card-detail-textarea:focus {
  outline: none !important;
  border-color: #6577FB;
}

.small-text-grey {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.str-chat {
  height: 100%;
}

.str-chat-channel.messaging .str-chat__main-panel {
  padding: 0px;
}

.messaging.str-chat .str-chat__header-livestream {
  border-radius: 0px;
}

.button-group button {
  font-family: Inter-Regular_;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 8px;
  background-color: #5060ff;
  padding: 9px 24px;
}

.button-group button:hover {
  color: #ffffff;
  background-color: #3f4ccc;
}

.task-board {
  overflow: auto;
  width: 100%;
  height: 100%;
}

.project-search {
  background: url("../img/search@2x.png") no-repeat scroll 7px 7px;
  background-size: 20px;
  padding-left: 40px;
  width: 288px;
  margin: 5px auto 0px;

}

.editable-textarea {
  overflow: hidden;
  overflow-wrap: break-word;
  resize: none;
  height: 20px;
  width: 100%;
  border-color: #091e4240;
}

.editable-textarea:focus {
  outline: none !important;
  border-color: #6577FB;
}

.dorpdown-menu-bkg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  cursor: auto;
}

.dashboard-dropdown-menu {
  z-index: 1200;
}

.board-lane-new {
  z-index: 1200;
}

.task-board .react-trello-board {
  height: 100%;
  overflow: auto;
  background-color: #F8F4F4;
  width: 100%;
}

.task-board .react-trello-lane {
  background-color: rgba(0, 0, 0, 0);
}

.task-board .react-trello-card {
  width: 250px;
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  box-shadow: 0px 4px 15px -2px rgba(27, 46, 94, 0.04);
  border-radius: 8px;
}

.board-card-new-form {
  width: 250px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  box-shadow: 0px 4px 15px -2px rgba(27, 46, 94, 0.04);
  border-radius: 8px;
}

.board-card-new-form-btns {
  margin-top: 28px;
  margin-bottom: 16px;
}

.board-card {
  height: 176px;
}

.board-card-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2E2E2E;
}

.board-card-description {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);

  display: block;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  max-height: 48px;
}

.board-card-comment {
  position: absolute;
  bottom: 20px;
}

.board-card-comment-count {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #757D8A;
}

.board-card-popup-header {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #111928;
}

.board-card-popup-text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.board-card-popup-text-grey {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #868686;
  width: 84px;
}

.board-card-popup-description-header {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #111928;
}

.board-card-popup-description-text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2E2E2E;
}

.board-card-popup-input-box {
  width: 100%;
  height: 0px;
  left: 24px;
  top: 170px;
  border-top: 0.5px solid rgba(0, 0, 0, 0.16);
  margin: auto;
}

.board-card-popup-comment-user {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #2E2E2E;
}

.board-card-popup-comment-time {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #868686;
}

.align-items-center {
  align-items: center;
}

.dashboard-header-members {
  position: relative;
  width: 104px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 12px 4px 4px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #F1F2F3;
  box-sizing: border-box;
  border-radius: 8px;
  margin-right: 24px;
  justify-content: center;
}

.dashboard-header-member {
  position: absolute;
  width: 32px;
  height: 32px;
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 41px;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.dashboard-header-member1 {
  background: linear-gradient(151.78deg, #F9EBF6 14.25%, #EBD6E7 85.85%);
  color: #B348AE;
}

.dashboard-header-member2 {
  background: linear-gradient(160.77deg, #FAE1DD 12.72%, #F7C6C1 87.21%);
  color: #BC291B;
  left: 20px;
}

.dashboard-header-member3 {
  background: linear-gradient(155.14deg, #E5ECFA 14.17%, #C9DDF1 85.07%);
  color: #5860BB;
  left: 40px;
}

.dashboard-header-member-count {
  position: absolute;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #868686;
  right: 12px;
}

.dashboard-docs-top {
  margin-left: 24px;
  margin-right: 24px;
}

.dashboard-docs-line {
  width: 100%;
  height: 0px;
  border-top: 0.5px solid rgba(0, 0, 0, 0.16);
}

.dashboard-docs-header {
  height: 48px;
}

.dashboard-docs-header-text {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #2E2E2E;
}

.dashboard-docs-header-desc {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #757D8A;
}

.dashboard-doc-upload {
  width: 326px;
  height: 72px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
}

.doc-upload-box {
  width: 32px;
  height: 32px;
  background: #5266FB;
  border-radius: 4px;
  display: inline-block;
  position: relative;
}

.doc-upload-box img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.ml20-auto {
  margin-left: 20px;
  margin-top: auto;
  margin-bottom: auto;
}

.doc-upload-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #2E2E2E;
}

.doc-upload-desc {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #868686;
}

.doc-upload-plus {
  font-size: 36px;
  margin-left: auto;
  margin-right: 20px;
}

.doc-upload-plus:hover {
  color: #0000FF;
}

.docs-grid-comment-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #868686;
  margin-right: 16px;
  margin-left: 7px;
  float: right;
}

.docs-empty {
  text-align: center;
}

.docs-empty-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #2E2E2E;
}

.profile-header {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #111928;
}

.profile {
  margin-left: 24px;
  margin-top: 24px;
}

.profile-image {
  width: 64px;
  height: 64px;
  border-radius: 50%;
}

.profile-name {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
  color: #111928;
  max-width: 420px;
}

.profile-email {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #AEAEAE;
}

.profile-display-name {
  margin-left: 24px;
  margin-right: 24px;
}

.profile-text-header {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #111928;
}

.profile-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #111928;
}

.profile-text-normal {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #374151;
}

.profile-text-desc {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: rgba(55, 65, 81, 0.66);
}

.profile-box {
  background: #FFFFFF;
  border: 1px solid #E5E7EB;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px;
}

.profile-account-actions {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 28px;
}

.mtb-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.profile-btn-white {
  background-color: #FFFFFF;
  border: 1px solid #E5E7EB;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 13px 20px;
}

.profile-btn-white:hover {
  background-color: #f5f5f5;
  text-decoration: none;
  color: #111928;
}

.tab-content {
  background: #FCFCFC;
  border-radius: 0px 0px 8px 8px;
}

.profile-subscription-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #2E2E2E;
}

.profile-subscription-text-grey {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #868686;
}

.profile-subscription-price {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
  color: #2E2E2E;
}

.profile-subscription-active {
  outline: none;
  border-color: #5266FB;
  box-shadow: 0 0 6px #5266FB;
}

.text-color-link {
  color: #5266FB;
}

/* Home */