@font-face {
  font-family: Inter;
  src: local(Inter-Regular),
    url(../fonts/Inter-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Inter-Regular_;
  src: local(Inter-Regular_Medium),
    url(../fonts/Inter-Regular_Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Inter-Regular_;
  src: local(Inter-Regular_SemiBold),
    url(../fonts/Inter-Regular_SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Inter-Regular_;
  src: local(Inter-Regular_Bold),
    url(../fonts/Inter-Regular_Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

.H1LeftWhite {
  font-family: Inter-Regular_;
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: var(--white);
}

.H1LeftBlack {
  font-family: Inter-Regular_;
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: var(--dark-grey);
}

.H1CenterWhite {
  font-family: Inter-Regular_;
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: var(--white);
}

.H2LeftWhite {
  font-family: Inter-Regular_;
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: var(--white);
}

.H2LeftBlack {
  font-family: Inter-Regular_;
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: var(--dark-grey);
}

.H3LeftWhite {
  font-family: Inter-Regular_;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: var(--white);
}

.H3LeftBlack {
  font-family: Inter-Regular_;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: var(--dark-grey);
}

.H3CenterWhite {
  font-family: Inter-Regular_;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: var(--white);
}

.H3CenterBlack {
  font-family: Inter-Regular_;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: var(--dark-grey);
}

.H4LeftBlack {
  font-family: Inter-Regular_;
  font-size: 21px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--dark-grey);
}

.H4CenterWhite {
  font-family: Inter-Regular_;
  font-size: 21px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: var(--white);
}

.H4CenterBlack {
  font-family: Inter-Regular_;
  font-size: 21px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #1a1b1c;
}

.SubheadingLeftWhite {
  font-family: Inter-Regular_;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--white);
}

.SubheadingLeftViolet {
  font-family: Inter-Regular_;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #5160ff;
}

.SubheadingLeftGray {
  font-family: Inter-Regular_;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--bluish-grey);
}

.SubheadingLeftBlack {
  font-family: Inter-Regular_;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--dark-grey);
}

.SubheadingCenterWhite {
  font-family: Inter-Regular_;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: var(--white);
}

.H5LeftWhite {
  font-family: Inter-Regular_;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: var(--white);
}

.H5LeftBlack {
  font-family: Inter-Regular_;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: var(--dark-grey);
}

.H5CenterWhite {
  font-family: Inter-Regular_;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: var(--white);
}

.H5CenterBlack {
  font-family: Inter-Regular_;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: var(--dark-grey);
}

.ParagraphLeftWhite {
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--white);
}

.ParagraphLeftViolet {
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--cornflower);
}

.ParagraphLeftGray {
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--bluish-grey);
}

.ParagraphLeftBlack {
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--dark-grey);
}

.ParagraphCenterBlack {
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: var(--dark-grey);
}

.LABELLeftBlack {
  font-family: Inter-Regular_;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 1px;
  color: var(--dark-grey);
}

.H6LeftWhite {
  font-family: Inter-Regular_;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--white);
}

.H6LeftViolet {
  font-family: Inter-Regular_;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #5160ff;
}

.H6LeftGray {
  font-family: Inter-Regular_;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--bluish-grey);
}

.H6LeftBlack {
  font-family: Inter-Regular_;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--dark-grey);
}

.H6CenterWhite {
  font-family: Inter-Regular_;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: var(--white);
}

.H6CenterBlack {
  font-family: Inter-Regular_;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: var(--dark-grey);
}

.LABELLeftWhite {
  font-family: Inter-Regular_;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.8px;
  color: var(--white);
}

.LABELCenterWhite {
  font-family: Inter-Regular_;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.8px;
  text-align: center;
  color: var(--white);
}

.LABELCenterGray {
  font-family: Inter-Regular_;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.8px;
  text-align: center;
  color: var(--bluish-grey);
}

.LABELCenterBlue {
  font-family: Inter-Regular_;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.8px;
  text-align: center;
  color: #00c3dc;
}

.LABELCenterBlack {
  font-family: Inter-Regular_;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.8px;
  text-align: center;
  color: var(--dark-grey);
}

.SmallLeftWhite {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--white);
}

.SmallLeftViolet {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--cornflower);
}

.SmallLeftGray-2 {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--very-light-pink);
}

.SmallLeftGray {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--bluish-grey);
}

.SmallLeftBlue {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--bright-sky-blue);
}

.SmallLeftBlack {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--dark-grey);
}

.SmallCenterWhite {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: var(--white);
}

.SmallCenterGray {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: var(--bluish-grey);
}

.SmallCenterBlack {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: var(--dark-grey);
}

link {
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #5160ff;
}

.Extra-smallRightWhite {
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--white);
}

.Extra-smallRightGray {
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  color: var(--bluish-grey);
}

.Extra-smallRightBlack {
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--dark-grey);
}

.Extra-smallLeftWhite {
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: var(--white);
}

.Extra-smallLeftViolet {
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #5160ff;
}

.Extra-smallLeftRed {
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--pale-red);
}

.Extra-smallLeftOrange {
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #ff8723;
}

.Extra-smallLeftGray {
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--bluish-grey);
}

.Extra-smallLeftBlue {
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: var(--bright-sky-blue);
}

.Extra-smallLeftBlack {
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--dark-grey);
}

.Extra-smallCenterGray {
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: var(--bluish-grey);
}