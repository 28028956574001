.channel__container {
  height: 100%;
  width: 100%;
}

.str-chat__thread {
  z-index: 1;
}

.str-chat__thread-list .str-chat__message-team {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.str-chat__thread-list .str-chat__message-actions-list button:first-child {
  display: none;
}

.str-chat__list .str-chat__reverse-infinite-scroll {
  padding-top: 0px;
}

.str-chat__thread-list .channel-empty__container {
  display: none;
}

.str-chat__date-separator {
  padding: 20px 40px;
}

.custom-thread-header {
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
}

.custom-thread-header__left {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.custom-thread-header__left-title {
  font-family: Helvetica Neue, sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #2c2c30;
  margin-right: 10px;
}

.custom-thread-header__left-count {
  font-family: Helvetica Neue, sans-serif;
  font-size: 14px;
  color: #858688;
}

.close-thread-icon {
  cursor: pointer;
  margin-right: 10px;
}

.str-chat__list--thread
  .str-chat__message-simple__actions__action--options
  .str-chat__message-actions-box {
  left: initial;
  right: 100%;
  border-radius: var(--border-radius-md);
}
