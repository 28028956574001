.str-chat__message-team:hover {
  background: #e9e9ea;
}

.str-chat__message-team-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.str-chat__message-team-content p {
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 120%;
  color: #000000;
  margin: 0;
  background: none;
}

.str-chat__message-team:hover .str-chat__message-team-content {
  background: none;
}

.str-chat__message-team-meta {
  justify-content: flex-start;
  align-items: center;
}

.str-chat__message-team--bottom time {
  display: none;
}

.str-chat__message-team--middle time {
  display: none;
}

.str-chat__message-team-meta time {
  visibility: visible;
  z-index: 2;
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: #858688;
  margin-top: 4px;
}

.str-chat__message-team-author strong {
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 120%;
  color: #858688;
  margin: 0;
  margin-right: 8px;
}

.str-chat__message-team-content--text {
  border-left: none;
}

.str-chat__message-team--received {
  padding-left: 20px;
}

.str-chat__message-replies-count-button {
  z-index: 2;
  display: flex;
  /* justify-content: flex-end; */
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: var(--primary-color);
}

.str-chat__message-replies-count-button:focus {
  outline: none;
}

.str-chat__message-team-actions {
  right: -3px;
}

.str-chat__date-separator-date {
  color: #858688;
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: normal;
}

.str-chat__avatar-image img.str-chat__avatar-image--loaded {
  display: none;
}

.str-chat__message-attachment {
  margin-bottom: 0;
}

.str-chat__message-attachment--image {
  max-width: 375px;
}

.str-chat__message-attachment-card--title {
  color: var(--primary-color);
}

.str-chat__message-team .str-chat__message-attachment-card {
  border-radius: 16px;
}

.str-chat__message-team .str-chat__message-attachment-card--content {
  background: #fff;
}

ul.str-chat__simple-reactions-list {
  display: flex;
  align-items: center;
  width: fit-content;
  background: #ffffff;
  border: 1px solid #e9e9ea;
  box-sizing: border-box;
  border-radius: 50vw;
  padding: 6px 6px 4px 6px;
  z-index: 3;
}

ul.str-chat__simple-reactions-list:hover {
  border: 1px solid var(--primary-color);
}

.str-chat__simple-reactions-list-item span {
  display: flex;
  align-items: center;
}

.str-chat__message-team-content ul {
  margin-top: 4px;
}

.emoji-mart-emoji-custom span {
  height: 15px !important;
  width: 15px !important;
}

.str-chat__simple-reactions-list-item--last-number {
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: #2c2c30;
}

.str-chat__message-attachment-actions-form {
  margin: 0px;
  padding: 10px 0;
  border-top: none;
  border-radius: 16px;
}

.str-chat__message-attachment-actions-button--primary {
  background: var(--primary-color);
  cursor: pointer;
}

.str-chat__message-attachment-actions-button--default {
  background: #fff;
  cursor: pointer;
}

.str-chat__message-attachment-actions-button:focus {
  box-shadow: none;
  border: 2px solid rgba(0, 0, 0, 0.08);
}

.str-chat__message-attachment-actions-button:hover {
  font-weight: 700;
  border: 1px solid var(--primary-color);
}

.str-chat__message-team-status {
  display: none;
}

.str-chat__message-actions-list button:hover {
  color: var(--primary-color);
}

.emoji-mart-anchor-selected {
  color: var(--primary-color) !important;
}

.emoji-mart-anchor-bar {
  background: var(--primary-color) !important;
}

.str-chat__message-team--editing {
  background: #e9e9ea;
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.06), inset 0 1px 0 0 var(--primary-color),
    inset 0 -1px 0 0 var(--primary-color);
  padding: 15px 20px;
}

.str-chat__message-team--editing .str-chat__message-team-form-footer {
  padding: 10px 0 0;
}

.str-chat__edit-message-form .str-chat__message-team-form-footer button[type='submit'] {
  color: var(--primary-color);
}

.str-chat__edit-message-form .str-chat__message-team-form-footer button {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.33);
  font-family: Helvetica Neue, sans-serif;
  font-size: 14px;
}

.str-chat__edit-message-form .str-chat__message-team-form-footer button:focus {
  outline: none;
}

.str-chat__edit-message-form textarea {
  box-shadow: 0 0 0 1px var(--primary-color);
}

.str-chat__edit-message-form textarea:focus {
  border: 1px solid var(--primary-color);
  box-shadow: 0 0 0 1px var(--primary-color);
}

.str-chat__edit-message-form svg {
  display: none;
}

.str-chat__message-team-content p a {
  color: var(--primary-color);
}

.pinned-message {
  background: #e6efff;
}

.unpinned-message {
  background: #fff;
}

.str-chat__message-team-pin-indicator {
  display: flex;
  align-items: center;
  margin-left: 40px;
  height: 18px;
}

.str-chat__message-team-pin-indicator div svg {
  fill: #858688;
  font-size: 10px !important;
}

.str-chat__message-team-pin-indicator div div {
  font-family: Helvetica Neue, sans-serif;
  font-size: 10px !important;
  line-height: 120%;
  color: #858688;
  margin-left: 4px;
}

.str-chat__reaction-selector {
  background: #ffffff;
  border: 1px solid #e9e9ea;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 99999px;
}

.str-chat__reaction-selector li {
  font-size: 24px;
}

.str-chat__reaction-selector li span span {
  height: 24px !important;
  width: 24px !important;
}

.str-chat__message-team-content--single:not(.str-chat__message-team-content--image)::before {
  display: none;
}
